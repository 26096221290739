import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { EventManagementService } from '../../services/event-management.service';
import { EventNode } from '../../classes/event_node';
import { SharedService } from '../../services/shared.service';
import { Product } from '../../classes/product';
import { EPSEvent } from '../../classes/event';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'tr[app-opted-out-node-row]',
  templateUrl: './opted-out-node-row.component.pug',
  styleUrls: ['./event-node-row.component.scss']
})
export class OptedOutNodeRowComponent implements OnChanges, OnInit {

  @Input() node: EventNode;
  @Input() product: Product;
  @Input() event: EPSEvent;
  @Input() selectingNodes:boolean;
  @Input() addingNote:boolean;
  @Input() columns;
  @Input() customerOffers;
  @Input() selectable:boolean;

  @Output() selectNode : EventEmitter<any> = new EventEmitter();

  customerOffer;
  clearedOffer;
  startTime:string;
  clearOffersTimeOut;

  constructor(private ems: EventManagementService, private sharedService: SharedService) { }
  private ngUnsubscribe: Subject<any> = new Subject();

  ngOnInit() {
  }

  hasColumn(name)
  {
    var num = _.findIndex(this.columns, c => {
      return c.header === name;
    });

    return num !== -1;
  }


  ngOnChanges(changes: SimpleChanges) {
    if(changes.customerOffers) this.setCustomerOffers();
    if(changes.event) this.onEventChange();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  confirmationModal(acceptanceFunction, header) : void {
    this.sharedService.activateModal({headerText: header, bodyText: "Are you sure?", allowCancel: true, confirmFunction: function() {
        acceptanceFunction();
      }});
  }

  clickSelect(): void {
    //Calls the function we pass in from the Event Detail Service
    this.selectNode.emit();
  }

  optIn() : void {
    const controller = this;
    this.confirmationModal(() => {
      this.ems.put('/v1/opt_in', [this.node.event_node_id], {}).pipe(takeUntil(this.ngUnsubscribe)
      ).subscribe(response => {
          controller.sharedService.popSuccess("Successfully opted in the Event Node. Updates may take up to 1 minute to display on this page.");
        },
        error => {
          this.sharedService.popError("Failed to opt in the Event Node!");
        });
    }, "Opt In Node");
  }

  clearCustomerOffers() {
    this.customerOffer = null;
    this.clearedOffer = null;
    this.startTime = null;
  }

  onEventChange() {
    const controller = this;
    if(this.event.event_end_dttm_utc && moment().utc().isBefore(moment(this.event.event_end_dttm_utc))) {
      clearTimeout(this.clearOffersTimeOut);
      const timeToEnd = moment.duration(moment(this.event.event_end_dttm_utc).diff(moment().utc())).as('milliseconds');
      this.clearOffersTimeOut = setTimeout( () => {
        controller.clearCustomerOffers();
      }, timeToEnd)
    } else if (this.event.event_end_dttm_utc && moment().utc().isSameOrAfter(moment(this.event.event_end_dttm_utc))) {
      controller.clearCustomerOffers();
    }
  }

  setCustomerOffers() {
    this.clearCustomerOffers();
    let arr = [];
    const co = this.customerOffers ?  _.find(this.customerOffers, ['registration_id', this.node.registration_id]) : null;
    if(co) {
      this.startTime = co.offer_start_dttm_utc;
      if(co.offer_value && co.offer_value.kW) {
        //customer offer
        this.customerOffer = this.sharedService.convertProductUOM(co.offer_value.kW, this.product, true, 3, true);
      }
      if(co.cleared) {
        if(co.cleared && co.cleared.prices) {
          if(co.cleared.prices.capacity) {
            arr.push('Capacity Price: ' + this.sharedService.convertProductUOM(co.cleared.prices.capacity, this.product, true, 3, true))
          }
          if(co.cleared.prices.energy) {
            arr.push('Energy Price: ' + this.sharedService.convertProductUOM(co.cleared.prices.energy, this.product, true, 3, false) + 'h')
          }
        }

        if(co.cleared && co.cleared.quantities) {
          if(co.cleared.quantities.capacity) {
            arr.push('Capacity Quantity: ' + this.sharedService.convertProductUOM(co.cleared.quantities.capacity, this.product, true, 3, true));
          }
          if(co.cleared.quantities.energy) {
            arr.push('Energy Quantity: ' + this.sharedService.convertProductUOM(co.cleared.quantities.energy, this.product, true, 3, false) + 'h');
          }
        }
      }
      this.clearedOffer = arr;
    }
  }
}
