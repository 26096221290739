import { Component, Input, OnInit } from '@angular/core';
import { ListTab, SharedService } from "../../../services/shared.service";
import { MqttService } from "ngx-mqtt";
import { EventManagementService } from "../../../services/event-management.service";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { FdrService } from "../../../services/fdr.service";
import { EventListService } from "../../../services/event-list.service";
import { EPSEvent } from "../../../classes/event";
import { Subject } from "rxjs/internal/Subject";
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import * as _ from 'lodash';
import { takeUntil } from "rxjs/operators";
import { EventNode, EventNodeWrapper } from "../../../classes/event_node";
import { Product } from "../../../classes/product";
import { EventExtendModalComponent } from "../../modals/event-extend-modal/event-extend-modal.component";
import { CustomModalWrapper } from "../../modals/enel-modal/enel-modal.component";
import { ModalDatePickerData } from "../../../classes/modalDatePickerData";
import { LoaderActivator } from 'src/app/classes/loader_activator';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-utility-portal-event-details',
  templateUrl: './utility-portal-event-details.component.pug',
  styleUrls: ['./utility-portal-event-details.component.scss']
})
export class UtilityPortalEventDetailsComponent implements OnInit {

  private ngUnsubscribe: Subject<any> = new Subject();

  comProductObj:Product;
  eventObj: EPSEvent = null;
  performanceData;
  perfInterval: any;
  events: Array<EPSEvent> = null;
  workflowStatuses = null;
  eventsLoaded:boolean = false;
  eventsFiltered:boolean = false;
  eventNodes:EventNodeWrapper = null;
  overallPerformance:number = 0;
  totalObligation:number = 0;
  currentPerformance:number = 0;
  changingEndTime:boolean = false;

  allNodes = [];

  listColumns = [];
  _selectedColumns = [];
  statuses = [];

  //MQTT
  currentEventSubs = [];
  eventSubscribe$;
  eventDataSubscribe$;
  eventSubscribed:boolean = false;

  showPerformance:boolean = true;
  showObligation:boolean = true;
  utilityConfig = {};
  filteredEvents = false;
  params: {program: string, product: string, start_time: string, type: string};

  constructor(
      private fdrService: FdrService,
      private router: Router,
      private sharedService: SharedService,
      private eventManagementService: EventManagementService,
      private authService: AuthService,
      private titleService:Title,
      private mqttService: MqttService,
      private epsMqttService: MqttService,
      private listService: EventListService,
      private route: ActivatedRoute
  ) {
      // Set the groups and whatnot for the new event
      const {unfilteredEvents$, loadingEvents$, workflowStatuses$} = this.listService;
      unfilteredEvents$.pipe().subscribe(resp => this.filterEvents(resp));

      loadingEvents$.pipe().subscribe(resp => this.eventsLoaded = !resp);

      workflowStatuses$.pipe().subscribe(resp => this.workflowStatuses = resp);
  }

  ngOnInit() {
    this.params = this.route.snapshot.queryParams as any;
    this.sharedService.setListTab((<any>ListTab['UTILITY_DETAILS']));

    this.statuses = [
      {label: 'Active', value: 'Active'},
      {label: 'Completed', value: 'Completed'},
    ];

    this.listColumns = [
      { field: 'eventNodeStatusForSort', header: 'Status', filter: true, options: this.statuses },
      { field: 'site_name', header: 'Site', filter: true },
      { field: 'registered_capacity_value', header: 'Expected Performance' },
      { field: 'last_current_performance_value', header: 'Last Performance Value' },
      { field: 'last_current_performance_percentage', header: 'Last Performance %' },
      { field: 'overall_performance_value', header: 'Overall Performance' },
      { field: 'average_performance_percentage', header: 'Average Performance %' },
      { field: 'shortfall', header: 'Shortfall' },
      { field: 'registration_type_display_label', header: 'Registration Type', filter: true },
      // { field: 'organization_display_label', header: 'Organization', filter: true },
      { field: null, header: 'Info' },
    ];

    // Fetch the utility config, then trigger the "events" lookup, which calls filterEvents
    this.eventManagementService.get('/v1/utility_config', {}).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {
      this.utilityConfig = response.data;
      this.listService.getEventsFromDate(true, true, this.params['start_time'], this.params['program'], this.params['type']);
    }, error => {
      console.dir("Error getting utility config: ");
      console.dir(error);
    });

    this._selectedColumns = this.listColumns;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  hasColumn(name) {
    var num = _.findIndex(this._selectedColumns, c => {
      return c.header === name;
    });

    return num !== -1;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.listColumns.filter(col => val.includes(col));
  }

  fixedDecimals(value, numDecimals = 3) {
    if(typeof(value) === 'number')
      return value.toFixed(numDecimals);
    else
      return null;
  }

  filterEvents(allEvents) {
    if(!this.params.program || !this.params.type || !this.params.start_time) {
      console.log("Incorrect URL parameters. Couldn't filter events.");
      this.eventsFiltered = true;
      return;
    }

    const programConfig = this.utilityConfig[this.params.program];
    this.showPerformance = (programConfig?.show_performance_column !== false);
    this.showObligation = (programConfig?.show_obligation !== false);

    this.events = allEvents.filter(ev => {
        return ev.program_id === this.params.program &&
               ev.product_id === this.params.product &&
               ev.event_start_dttm_utc === this.params.start_time &&
               ev.status !== "Cancelled";
    });

    if(this.events.length) {
        this.eventObj = this.events[0];
    }

    this.sharedService.setProgressStatus(this.eventObj)

    this.eventsFiltered = true;
    this.subscribeToPerformance();

    // Get the product from COM now that we have the event. But only if we don't have it yet.
    if (!this.comProductObj) {
      this.eventManagementService.get('/v1/product/' + this.eventObj.product_id, {})
        .toPromise()
        .then((response) => {
            this.comProductObj = response.data;

            //Set defaults for target_type related attributes
            if (!this.comProductObj.target_type) {
              this.comProductObj.target_type = "DROP_BY";
            }
            if (!this.comProductObj.performance_target_min) {
              this.comProductObj.performance_target_min = 100;
            }
            if (this.comProductObj.target_type === "RANGE" && !this.comProductObj.performance_target_max) {
              this.comProductObj.performance_target_max = this.comProductObj.performance_target_min * 1.5;
            }
        })
        .catch((error) => {
            console.dir("Error getting product");
            console.dir(error);
        });
    }
  }

  fetchEventNodes(): Promise<void> {
    const event_ids = this.events.map(e => e.event_id);

    // Subscribe to any data changes for our events
    if (!this.eventSubscribed) {
      this.eventSubscribed = true;
      this.subscribeToAllEventData();
    }

    // original @GP comment preserved for now:
    // * Call enuts to get the nodes using the IDs we just got
    // * There's probably a better way to do this but idk man it works
    return this.eventManagementService
        .post('/v1/events/event_nodes?utility=true', {event_ids}, {})
        .toPromise()
        .then((resp) => {
            // Filter nodes by event and also their status - GT note: it's unclear
            // why we're forEach'ing but only saving the last one. Is it because
            // we only expect one event's nodes back?
            resp.data.forEach((nodeGroup) => {
              let enw = new EventNodeWrapper();
              const grouped = {
                  activeNodes: nodeGroup.activeNodes,
                  pendingNodes: nodeGroup.pendingNodes,
                  optedOutNodes: nodeGroup.optedOutNodes,
                  completedNodes: nodeGroup.completedNodes,
                  excludedNodes: nodeGroup.excludedNodes
              };
              enw.setEventNodes(
                  grouped,
                  this.getWorkflowStatusesMap(),
                  this.getTemplatesMap(false),
                  this.getRegistrationTypesMap()
              );
              this.eventNodes = enw;
            });
            this.aggregatePerformance();
        })
        .catch((error) => {
            this.sharedService.popError("Failed to get event node workflows! " + JSON.stringify(error));
            console.dir(error);
        });
  }

  aggregatePerformance() {
      // Don't go negative - that means we're performing well and it might
      // be confusing to see a negative number in the table
      this.eventNodes.activeNodes.forEach((node) => {
        node.shortfall = Math.max(node.shortfall, 0);
        node.overall_performance_value = this.sharedService.getOverallNodePerformance(node, this.comProductObj);
      });
      this.allNodes = this.eventNodes.activeNodes;
      this.totalObligation = 0;
      // Loop through our events and custom tasks to see what bulk actions are available
      this.currentPerformance = this.allNodes.reduce((r, node) => r + node.last_current_performance_value, 0);
      this.totalObligation += this.allNodes.reduce((r, node) => r + node.registered_capacity_value, 0);
      this.overallPerformance = this.events.reduce((r, ev) => r + this.sharedService.getOverallPerformance(ev, this.comProductObj), 0);
  }

  subscribeToAllEventData() {
    const event_ids = this.events.map(e => e.event_id);

    // Unsubscribe to the previous one and subscribe to the new batch of events
    this.currentEventSubs.forEach(sub => sub.unsubscribe());

    // Clear it
    this.currentEventSubs = [];

    const onMessage = _.throttle(() => {
        try {
            this.fetchEventNodes();
        } catch(err) {
            console.log("Error updating event in UI: " + JSON.stringify(err));
        }
    }, 5000);

    this.sharedService.subscribeToTopic(
        null,
        event_ids,
        "eps_event",
        this.epsMqttService,
        msg => onMessage(),
        true,
        this.currentEventSubs,
        this.ngUnsubscribe,
        this.eventManagementService
    );
  }

  subscribeToPerformance() {
      if (this.perfInterval) return;

      const event_ids = this.events.map(e => e.event_id);

      // We'll get a ton of messages all at once. Call to refresh the
      // performance data at most once per 5 seconds.
      const perfParams = {event_ids, performance_aggregate_type: "PORTFOLIO"};
      const fetchPerformance = _.throttle(() => {
          return Promise.all([
              this.fetchEventNodes(),
              this.eventManagementService
                  .post('/v1/event_performance', perfParams, {})
                  .toPromise()
                  .then(response => this.performanceData = response.data)
                  .catch(err => {
                      console.dir("Error getting event performance data: ");
                      console.dir(err);
                  })
            ]);
      }, 5000);

      // Get performance and active event nodes, then subscribe to any changes
      this.perfInterval = setInterval(() => fetchPerformance(), 60 * 1000);
      fetchPerformance().then(() => {
          const allNodeIDs = this.allNodes.map(node => node.event_node_id.toString());
          this.sharedService.subscribeToTopic(
              null, // unused variable
              allNodeIDs,
              'NODE_PERFORMANCE',
              this.epsMqttService,
              msg => fetchPerformance(),
              false,
              [],
              this.ngUnsubscribe,
              this.eventManagementService
          );
      });
  }

  getEventType(code, type) {
    //Some programs have an override for this event type
    if (code === "VOLUNTARY") {
      if (this.utilityConfig[this.params['program']] && this.utilityConfig[this.params['program']].voluntary_event_type_override)
        return this.utilityConfig[this.params['program']].voluntary_event_type_override;
      else
        return "Official - Emergency";
    }
    return type;
  }

  getPerfDisplay(node: EventNode, type) {
    this.sharedService.setProgressStatus(this.eventObj);
    if (node.estimate_performance_ind) {
      return ' - ';
    } else {
      const status = this.eventObj.event_progress_status;
      switch (type) {
        case 'expectedValue':
          return this.sharedService.convertProductUOM(node.registered_capacity_value, this.comProductObj, true);
        case 'lastValue':
          if(status === 'BEFORE') {
            return '';
          } else if( status === 'DURING') {
            return this.sharedService.convertProductUOM(node.last_current_performance_value, this.comProductObj, true);
          } else {
            return ' - ';
          }
        case 'lastPercent':
          if(status === 'BEFORE') {
            return '';
          } else if( status === 'DURING') {
            return this.fixedDecimals(node.last_current_performance_percentage, 1);
          } else {
            return ' - ';
          }
        case 'overAllValue':
          return status === 'AFTER' ? this.sharedService.convertProductUOM(node.overall_performance_value, this.comProductObj, true) : '';
        case 'averagePercent':
          return status === 'AFTER' ? this.fixedDecimals(node.average_performance_percentage, 1) : '';
        case 'shortfall':
          return status === 'BEFORE' ? '' : this.sharedService.convertProductUOM(node.shortfall, this.comProductObj, true);
        default:
          return '';
      }
    }
  }

  getWorkflowStatusesMap() {
    return Object.values(this.listService.workflowStatusesMap);
  }

  getRegistrationTypesMap() {
    return Object.values(this.listService.registrationTypesMap);
  }

  getTemplatesMap(getStorage = true) {
    if(getStorage)
      return Object.values(this.listService.templatesMap);
    else
      return Object.values(this.listService.createableTemplatesMap);
  }

  getFiltersMap(getStorage = true) {
    if(getStorage)
      return Object.values(this.listService.filterTypesMap);
    else
      return Object.values(this.listService.createableFilterTypesMap);
  }

  eventEndInPast(): boolean {
    return this.eventObj.event_progress_status === 'AFTER';
  }

  showMoreInfo(eventNode): void {
    const url = window.location.origin + '/utility/eventnode/' + eventNode.event_node_id;
    window.open(url, '_blank');
  }

  endEvent(): void {
    const controller = this;

    let bulkEvents = [];

    this.events.forEach(event => {
      bulkEvents.push({event: event, obligation: null});
    });

    let start = this.eventObj.event_start_dttm_utc;
    let end = !!this.eventObj.event_end_dttm_utc ? this.eventObj.event_end_dttm_utc :  moment(start).add(this.comProductObj.max_event_duration, 'ms').toISOString();
    let timePickerDataEnd = new ModalDatePickerData(this.eventObj.event_end_dttm_utc, 'End', start, null, this.comProductObj.max_event_duration, false, false, false, false);

    this.sharedService.activateModal({
      headerText: "Update Event End Time",
      bodyText: "Updating " + (bulkEvents.length) + " events.",
      customContent: new CustomModalWrapper(EventExtendModalComponent, {
        program_time_zone: this.comProductObj.timezone,
        events: bulkEvents,
        isUtility: true,
        timePickerData: [timePickerDataEnd],
        buttonText: 'Update End Time',
        utility: true,

        confirmFunction: function (time, evData) {

          let eventIds = [];
          evData.forEach((e) => {
            eventIds.push(e.event.event_id);
          });

          let body =  {
            event_ids : eventIds
          };

          controller.sharedService.activateLoader(new LoaderActivator(new Promise(function (resolve, reject) {
            resolve(controller.eventManagementService.put('/v1/utility_event/event_end_time/' + time[0], body, {}))
          }), function () {
            controller.sharedService.popSuccess("Success! The end time has been updated. It may take up to 5 minutes to see it reflected on this page. If you don't see the new end time within 5 minutes please call Enel X at 617-692-2003.");
          }, function (error) {
            console.dir(error.error);
          }, false, "Updating End Times", false, false));
        }
      })
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    clearInterval(this.perfInterval);
  }
}
