import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Subject } from "rxjs/internal/Subject";
import { takeUntil } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { EPSEvent } from "../../classes/event";
import { EventNode, EventNodeStatus } from "../../classes/event_node";
import { ModalDatePickerData } from "../../classes/modalDatePickerData";
import { Product } from "../../classes/product";
import { CustomModalWrapper } from "../../components/modals/enel-modal/enel-modal.component";
import { TimestampModalComponent } from "../../components/modals/timestamp-modal/timestamp-modal.component";
import { BulkService } from '../../services/bulk.service';
import { EventManagementService } from "../../services/event-management.service";
import { SharedService } from "../../services/shared.service";

@Component({
  selector: 'tr[app-event-node-row]',
  templateUrl: './event-node-row.component.pug',
  styleUrls: ['./event-node-row.component.scss']
})
export class EventNodeRowComponent implements OnInit {

  @Input() workflowStates;
  @Input() caseTypes;
  @Input() eventNode: EventNode;
  @Input() event: EPSEvent;
  @Input() product: Product;
  @Input() selectingNodes:boolean;
  @Input() selectable:boolean;
  @Input() columns;
  @Input() aggregateView: boolean;
  @Input() isTraining: boolean = false;

  @Output() selectNode : EventEmitter<any> = new EventEmitter();


  eventNodeStatuses;
  columnMap: Map<string, boolean> = new Map<string, boolean>();
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private eventManagement: EventManagementService, private sharedService: SharedService, private BS: BulkService) { }

  ngOnInit() {
    this.eventNodeStatuses = EventNodeStatus;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['columns']) {
      this.buildColumnMap();
    }
  }

  buildColumnMap() {
    this.columnMap.clear();
    this.columns.forEach(column => {
      this.columnMap.set(column.header, true);
    });
  }

  hasColumn(name: string) {
    return this.columnMap.has(name);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  hasStarted(){
    return moment(this.eventNode.event_node_start_dttm_utc).isBefore(moment())
  }

  hasEnded(){
    return this.eventNode.event_node_end_dttm_utc && moment(this.eventNode.event_node_end_dttm_utc).isBefore(moment())
  }

  clickSelect(): void {
    //Calls the function we pass in from the Event Detail Service
    this.selectNode.emit();
  }

  goToEventDetail(node) {
    const urlSnippet = this.isTraining ? '/training/event/' : /event/;
    const url = window.location.origin + urlSnippet + node.event_id;
      window.open(url, '_blank');
  }
  confirmationModal(acceptanceFunction, header) : void {
    const controller = this;

    this.sharedService.activateModal({headerText: header, bodyText: "Are you sure?", allowCancel: true, confirmFunction: function() {
        acceptanceFunction();
      }});
  }

  customConfirmationModal(acceptanceFunction, header, timePickerData: Array<ModalDatePickerData>) : void {
    const controller = this;

    this.sharedService.activateModal({
      headerText: header,
      customContent: new CustomModalWrapper(TimestampModalComponent, {
        program_time_zone: controller.product.timezone,
        confirmFunction: function(time) {
          acceptanceFunction(time);
        },
        timePickerData: timePickerData
      })
    });
  }

  resolveException() : void {
    const url = environment.aunt_url +
      '/exceptions?event_node_id__eq=' + this.eventNode.event_node_id +
      '&workflow_status__eq=' + this.eventNode.workflow_status +
      '&redirect=true';
    window.open(url, '_blank');
  }

  recurtail() : void {
    const controller = this;

    let timePickerDataStart = new ModalDatePickerData(null, 'Start', this.event.event_start_dttm_utc, this.event.event_end_dttm_utc, 0,true, false, true, false);

    this.customConfirmationModal((times) => {
    const reqObj = [{
      event_node_id: this.eventNode.event_node_id,
      curtail_time: times[0]
    }];

    this.eventManagement.put('/v1/recurtail', reqObj, {}).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {
        controller.sharedService.popSuccess("Successfully re-activated Event Node. Updates may take up to 1 minute to display on this page.")
      },
      error => {
        this.sharedService.popError("Failed to re-activate Event Node!");

      });
    }, "Re-Activate Node",[timePickerDataStart]);
  }

  isBeforeCurtailTime(){
    return moment(this.eventNode.curtail_time).isAfter(moment())
  }

  overrideControl(){
    this.BS.overrideControl([this.eventNode.event_node_id])
  }

  optOut(){
    const controller = this;
    let postObj = {event_nodes:[this.eventNode.event_node_id]}
    //event_nodes/opt_out
  }

  cancel(){
    this.BS.cancelNodes([this.eventNode.event_node_id])
  }

  activate() : void {
    const controller = this;
    let timePickerDataStart = new ModalDatePickerData(null, 'Start', this.event.event_start_dttm_utc, this.event.event_end_dttm_utc, 0,true, false, true, false);
    let timePickerDataEnd = new ModalDatePickerData(this.event.event_end_dttm_utc, 'End', null, this.event.event_end_dttm_utc, 0,false, true, false, !this.event.event_end_dttm_utc);


    this.customConfirmationModal((times) => {
      this.eventManagement.put('/v1/activate', { event_nodes: [this.eventNode.event_node_id], start_time: times[0], end_time: times[1]}, {}).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {
          controller.sharedService.popSuccess("Successfully activated Event Node. Updates may take up to 1 minute to display on this page.")
        },
      error => {
        this.sharedService.popError("Failed to activate Event Node!");
        console.dir(error)

      });
    }, "Activate Node", [timePickerDataStart, timePickerDataEnd]);
  }

  scheduleEnd() : void {
    const controller = this;

    let timePickerDataEnd = new ModalDatePickerData(null, 'End', this.event.event_start_dttm_utc, this.event.event_end_dttm_utc, 0, false, true, true, false);

    this.customConfirmationModal((times) => {

      this.eventManagement.put('/v1/schedule_end', { event_nodes: [this.eventNode.event_node_id], end_time: times[0], product_id: this.product.id}, {}).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {
          controller.sharedService.popSuccess("Successfully ended Event Node. Updates may take up to 1 minute to display on this page.");
        },
      error => {
        this.sharedService.popError("Failed to end Event Node!");
      });
    }, "End Node", [timePickerDataEnd]);
  }

  eventNodeHasException():boolean {
    if(this.eventNode.workflow_status === 'CURTAIL_EXCEPTION_DETECTED' || this.eventNode.workflow_status === 'RESTORE_EXCEPTION_DETECTED') {
      return true;
    }

    return false;
  }

  showMoreInfo(): void {
    const urlSnippet = this.isTraining ? '/training/eventnode/' : /eventnode/;
    const url = window.location.origin + urlSnippet + this.eventNode.event_node_id;
    window.open(url, '_blank');
  }

  fixedDecimals(value: any, numDecimals: number = 3): string | null {
    if (typeof value !== 'number' || !isFinite(value)) {
      return null;
    }
    return value.toFixed(numDecimals);
  }

  getEventNodeStatusLabel(eventNodeStatusForSort) {
    const statusMap = {
      'active': 'ACTIVE',
      'pending': 'PENDING',
      'completed': 'COMPLETED',
      'excluded': 'EXCLUDED',
      'cancelled': 'CANCELLED'
    };
    
    return statusMap[eventNodeStatusForSort] ?? '';
  }
}
