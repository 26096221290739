import { animate, group, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { EPSEvent } from '../../../classes/event';
import { EventNode } from '../../../classes/event_node';
import { Product } from '../../../classes/product';
import { SharedService } from '../../../services/shared.service';
import { CookieService } from 'ngx-cookie-service';
import { of, Subject, Subscription } from 'rxjs';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import { EventNodeIot } from '../../../classes/node-performance';
import { MqttService } from 'ngx-mqtt';
import { EventManagementService } from '../../../services/event-management.service';


@Component({
  selector: 'app-npc-banner',
  templateUrl: './npc-banner.component.pug',
  styleUrls: ['./npc-banner.component.scss'],
  animations: [
    trigger('toggle', [
      transition(':enter', [
        style({'max-height': 0, 'padding': 0}),
        group([
          animate('200ms ease-out', style({ padding: '20px 30px' })),
          animate('500ms ease-out', style({ 'max-height': 500 })),
        ]),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ 'height': 0, 'padding-top': 0, 'padding-bottom': 0, 'padding-right': 0, 'padding-left': 0 })),
      ]),
    ]),
    trigger('fade', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class NpcBannerComponent implements OnInit, OnDestroy {

  @Input() event: EPSEvent;
  @Input() eventNode: EventNode;
  @Input() product: Product;
  @Input() utility:boolean = false;

  tzSubscription: Subscription;
  performanceStatus: string;
  progressStatus: string;
  isFSL: boolean;
  isEstimated: boolean;
  isAfter: boolean;
  asOfSuffix: string = '';
  headerPrimary;
  headerSecondary;
  trayPrimaryText;
  traySecondaryTitle;
  traySecondaryText;
  expanded = false;
  capacityValue;
  capacityUOM;
  tz;
  tzShort;
  private ngUnsubscribe: Subject<any> = new Subject();
  private nodeSubscribe$;


  constructor(private SS: SharedService, private cookieService: CookieService, private MQTT: MqttService, private ems: EventManagementService) {

  }

  ngOnInit() {
    this.render();
    this.subscribeNode();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  render(){
    this.SS.setProgressStatus(this.event);
    this.isFSL = this.eventNode.is_fsl_indicator == true;
    this.isEstimated = this.eventNode.estimate_performance_ind === true;
    this.isAfter = this.event.event_progress_status == 'AFTER';
    this.capacityValue = this.utility ? this.eventNode.registered_capacity_value : this.eventNode.expected_capacity_value;
    this.capacityUOM = this.utility ? this.eventNode.registered_capacity_uom : this.eventNode.expected_capacity_uom;

    this.tz = this.event.full_time_zone;
    this.tzShort = moment().tz(this.tz).format('z');

    if (this.eventNode.last_current_performance_dttm_utc) {
      this.asOfSuffix = ' as of ' + moment.tz(this.eventNode.last_current_performance_dttm_utc, this.tz).format(SharedService.dateFormat) + ' ' + this.tzShort
    }
    this.headerSecondary = this.getHeaderSecondary();

    if (this.isAfter) {
      this.performanceStatus = this.eventNode.average_performance_status;
    } else {
      this.performanceStatus = this.eventNode.last_current_performance_status;
    }

    if (this.eventNode.last_current_performance_dttm_utc) {
      this.asOfSuffix = ' as of ' + SharedService.LocalToFormattedSelectedTimeZone(this.eventNode.last_current_performance_dttm_utc, this.event.full_time_zone) + ' ' + this.cookieService.get('etz')
    }

    this.headerPrimary = this.getHeaderPrimary();
    this.headerSecondary = this.getHeaderSecondary();
    this.trayPrimaryText = this.getTrayPrimaryText();
  }

  subscribeNode() {
    const controller = this;
    controller.SS.subscribeToTopic(controller.nodeSubscribe$, [controller.eventNode.event_node_id], 'NORMALISED_NODE', controller.MQTT, (msg)=> {
      of(msg as EventNodeIot).pipe().subscribe(
        (resp)=> {
          this.event = Object.assign({}, this.eventNode, msg);
          this.render();
        },
        (error)=> {
          console.log(error)
        }
      )
    }, false, [], controller.ngUnsubscribe, controller.ems)
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }

  getPerformancePercent(node:EventNode, product: Product) {
      const performanceVal = this.SS.getOverallNodePerformance(node, product);
      const expectedCapacity = node.expected_capacity_value;
      const performancePerCent = expectedCapacity === 0 ? 0 : Math.round(performanceVal / expectedCapacity * 100);
      return performancePerCent.toFixed(0) + '%'
  }

  isEPSEvent(ev: EPSEvent | EventNode): ev is EPSEvent {
    return (ev as EPSEvent).event_id !== undefined;
  }

  getPerformanceClass() {
    switch (this.performanceStatus) {
      case 'PERFORMING':
        return 'performance-good';
      case 'PERFORMING_NEAR_EXPECTATION':
        return 'performance-meh';
      case 'UNDER_PERFORMING':
        return 'performance-bad';
      default:
        return 'performance-none'
    }
  }

  getHeaderPrimary() {
    if(!this.performanceStatus || this.performanceStatus === 'ONLY_PERF_AVAILABLE' || this.performanceStatus === 'ONLY_TARGET_AVAILABLE' || this.performanceStatus === 'NO_PERF_NUMBERS') {
      return 'No performance data available at this location.'
    } else if(this.isAfter) {
      if(this.isFSL) {
        return 'The dispatch is now over.'
      } else {
        switch (this.performanceStatus) {
          case 'PERFORMING':
            return 'Performed as expected.';
          case 'PERFORMING_NEAR_EXPECTATION':
            return 'Nearly performed as expected.';
          case 'UNDER_PERFORMING':
            return 'Did not perform as expected.';
        }
      }
    } else {
      switch (this.performanceStatus) {
        case 'PERFORMING':
          return 'Performing as expected.';
        case 'PERFORMING_NEAR_EXPECTATION':
          return 'Nearly performing as expected.';
        case 'UNDER_PERFORMING':
          return 'Not yet performing as expected.';
      }
    }
  }

  getHeaderSecondary() {
    if(!this.performanceStatus || this.performanceStatus === 'NO_PERF_NUMBERS') {
      return ''
    } else {
      if(this.isFSL) {
        switch (this.performanceStatus) {
          case 'ONLY_TARGET_AVAILABLE':
            return 'Target: ' + this.eventNode.firm_service_level_value.toLocaleString() + ' kW';
          case 'ONLY_PERF_AVAILABLE':
          case 'UNDER_PERFORMING':
          case 'PERFORMING_NEAR_EXPECTATION' :
            if(this.isAfter) {
              return 'Overall Performance of the event is ' + this.SS.getOverallNodePerformance(this.eventNode, this.product).toLocaleString() + ' kW' + this.asOfSuffix;
            } else {
              return 'Current Performance of the last interval is ' + this.eventNode.last_current_performance_value.toLocaleString() + ' kW' + this.asOfSuffix;
            }
          case 'PERFORMING':
          default:
            if(this.isAfter) {
              return 'Overall performance met target ' + this.asOfSuffix;
            } else {
              return 'Current demand of the last interval is ' + this.eventNode.last_current_metered_value.toLocaleString() + ' kW' + this.asOfSuffix;
            }
        }
      } else {
        switch (this.performanceStatus) {
          case 'ONLY_TARGET_AVAILABLE':
            return 'Expected reduction: ' + this.capacityValue.toLocaleString() + ' kW' + this.asOfSuffix;
          case 'ONLY_PERF_AVAILABLE':
            if(this.isAfter) {
              return 'Overall Performance of the event is ' + this.SS.getOverallNodePerformance(this.eventNode, this.product).toLocaleString() + ' kW' + this.asOfSuffix;
            } else {
              return 'Current Performance of the last interval is ' + this.eventNode.last_current_performance_value.toLocaleString() + ' kW' + this.asOfSuffix;
            }
          default:
            if(this.isAfter) {
              return 'Overall Performance of the event is ' + this.SS.getOverallNodePerformance(this.eventNode, this.product).toLocaleString() + ' of Expected ' + this.capacityValue.toLocaleString() + ' kW ' + this.getPerformancePercent(this.eventNode, this.product) + this.asOfSuffix;
            } else {
              return 'Current Performance of the last interval is ' + this.eventNode.last_current_performance_value.toLocaleString() + ' of Expected ' + this.capacityValue.toLocaleString() + ' kW ' + this.eventNode.last_current_performance_percentage.toLocaleString() + '%' + this.asOfSuffix;
            }
        }
      }
    }
  }

  getTrayPrimaryText() {
    switch (this.performanceStatus) {
      case 'NO_PERF_NUMBERS':
      case 'ONLY_PERF_AVAILABLE':
      case 'ONLY_TARGET_AVAILABLE':
      case null:
        if(this.isEstimated) {
          return 'Real-time readings are not showing up as this is a non-metered site. We will inform you once the readings are obtained from the utility.'
        } else {
          return 'We are not detecting any performance data for this location.';
        }
    }
  }

}
