import { Component, Input } from '@angular/core';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import { EventNodeBulkOperation } from 'src/app/classes/event_node';
import { ModalDatePickerData } from '../../../classes/modalDatePickerData';
import { SharedService } from '../../../services/shared.service';
import { CustomModalData } from '../enel-modal/enel-modal.component';

@Component({
  selector: 'app-event-node-bulk-end-modal',
  templateUrl: './event-node-bulk-end-modal.component.pug',
  styleUrls: ['./event-node-bulk-end-modal.component.scss'],
})
export class EventNodeBulkEndModalComponent implements CustomModalData {
  defaultDateWarning = false;
  durationErrorMsg = '';
  durationWarningMsg = '';
  showTimezoneTable = true;
  defaultEndTimeConsideration = '';
  portfolios = {};
  isAggregate: boolean = false;
  passwordRequired = 'ennel1';
  passwordEntered = '';
  operation = 'end';
  hasErrors = false;
  hasDifferentEndTimes = false;
  selectedTz: string = 'UTC';

  constructor(private shared: SharedService) {}

  @Input() data: any;

  ngOnInit() {
    if (this.data.utility && this.data.utility == true) {
      this.showTimezoneTable = false;
    }

    this.isAggregate = !!this.data.isAggregate;
    this.passwordRequired = this.setRequiredPassword();

    this.selectedTz = this.shared.getTimeZoneName(this.data.program_time_zone);

    //Set the default dates if there are any
    this.data.timePickerData.forEach(tpd => {
      if (tpd.default_date) {
        let convertedTime = moment.tz(moment(tpd.default_date), this.shared.getTimeZoneName(this.data.program_time_zone, false));
        tpd.entered_time = convertedTime.format('YYYY-MM-DDTHH:mm');
      }
    });

    if (this.data.hasDifferentEndTimes) {
      const OUTPUT_DATE_FORMAT = 'YYYY/MM/DD, HH:mm z';
      let endDate = '2050/01/01, 00:00 UTC';
      if (!!this.data.eventEnd) {
        endDate = moment.tz(moment.utc(this.data.eventEnd), this.data.program_time_zone).format(OUTPUT_DATE_FORMAT);
      }
      this.defaultDateWarning = true;
      this.defaultEndTimeConsideration =
        'Default end time corresponds to the earliest event end time of the group of selected nodes. Entered date must be before ' +
        endDate;
    }

    this.operation = this.data?.operation.toLowerCase() ?? 'end';
  }

  setRequiredPassword() {
    if (this.isAggregate) {
      if (this.data.passwordRequired) {
        return this.data.passwordRequired;
      }
      return 'ennel1';
    }
    return null;
  }

  cannotConfirm() {
    return !this.isPasswordValid() || this.hasErrors || this.isDateFieldEmptyOrInvalid(this.data.timePickerData[0]);
  }

  confirm() {
    const hasErrors = this.validateDates();
    if (hasErrors) return;

    const eventNodeIds = this.data.event_nodes.map((node: EventNodeBulkOperation) => node.event_node_id);
    const times = this.getTimepickerDataInCorrectTZ();
    this.data.confirmFunction(times, eventNodeIds);
    this.data.close();
  }

  close() {
    this.data.close();
  }

  getTimezone() {
    return this.shared.getTimeZoneName(this.data.program_time_zone);
  }

  getTimeZoneConversion(timePickerData: ModalDatePickerData, zone) {
    let localTZ = this.shared.getUsersTimeZone();
    let tz;

    switch (zone) {
      case 'local':
        tz = localTZ;
        break;
      case 'program':
        tz = this.data.program_time_zone;
        break;
      case 'utc':
        tz = 'UTC';
        break;
    }

    let convertedTime: string = this.shared.selectedTimeZoneToUTC(timePickerData.entered_time, this.data.program_time_zone).toISOString();
    return timePickerData.entered_time ? moment.tz(convertedTime, tz).format(SharedService.dateFormat) : 'None';
  }

  getTimepickerDataInCorrectTZ(): string[] {
    return this.data.timePickerData.map(tpd => {
      if (tpd.entered_time) {
        if (moment.isMoment(tpd.entered_time)) {
          return tpd.entered_time.clone().tz(this.data.program_time_zone, true).toISOString();
        }
        return moment.tz(tpd.entered_time, this.data.program_time_zone).toISOString();
      }
      return null;
    });
  }

  onChangeDate(time, tpd) {
    tpd.entered_time = time.date;
    tpd.errors = [];

    this.hasErrors = this.validateDates();
  }

  validateDates(): boolean {
    let hasError = false;
    this.data.timePickerData.forEach(tpd => {
      const dateInPickerTZ = moment.tz(moment(tpd.entered_time).format('YYYY-MM-DDTHH:mm'), this.data.program_time_zone);
      hasError = hasError || this.isDateFieldEmptyOrInvalid(tpd) || this.validateDateRange(tpd, dateInPickerTZ);
    });
    return hasError;
  }

  private isDateFieldEmptyOrInvalid(tpd: ModalDatePickerData): boolean {
    const isEmptyOrInvalid = !tpd.entered_time && !tpd.allow_null;
    if (!moment(tpd.entered_time).isValid() && !tpd.allow_null) {
      tpd.errors = ['Invalid date entered.'];
    }
    return isEmptyOrInvalid;
  }

  private isPasswordValid(): boolean {
    return this.passwordRequired === null || this.passwordEntered === this.passwordRequired;
  }

  private validateDateRange(tpd: ModalDatePickerData, chosenDateUTC: moment.Moment): boolean {
    let hasError = false;
    if (tpd.min_date) {
      const minDate = moment(tpd.min_date);
      if (tpd.min_date_inclusive) {
        if (chosenDateUTC.isBefore(minDate)) {
          tpd.errors.push('Entered date must be the same as or after the event start time.');
          hasError = true;
        }
      } else {
        if (chosenDateUTC.isSameOrBefore(minDate)) {
          tpd.errors.push('Entered date must be after the event start time.');
          hasError = true;
        }
      }
    }
    if (tpd.max_date) {
      const maxDate = moment(tpd.max_date);
      if (tpd.max_date_inclusive) {
        if (chosenDateUTC.isAfter(maxDate)) {
          tpd.errors.push('Entered date must be before the earliest event end time.');
          hasError = true;
        }
      } else {
        if (chosenDateUTC.isSameOrAfter(maxDate)) {
          tpd.errors.push('Entered date must be before the event end time.');
          hasError = true;
        }
      }
    }
    return hasError;
  }
}
