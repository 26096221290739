import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import * as Highcharts from "highcharts";
import More from "highcharts/highcharts-more";
import Heatmap from "highcharts/modules/heatmap";
import Timeline from "highcharts/modules/timeline";
import Tree from "highcharts/modules/treemap";
import * as _ from "lodash";
import * as Moment from "moment";
import * as moment from "moment-timezone/builds/moment-timezone-with-data-2012-2022.min";
import {MqttService} from "ngx-mqtt";
import {ToastrService} from "ngx-toastr";
import {Subject} from "rxjs/internal/Subject";
import {takeUntil} from "rxjs/operators";
import {EPSEvent} from "../../classes/event";
import {EventNode, GroupedEventNodes} from "../../classes/event_node";
import {LoaderActivator} from "../../classes/loader_activator";
import {ModalDatePickerData} from "../../classes/modalDatePickerData";
import {EnelTabsComponent} from "../../directives/enel-tabs/enel-tabs.component";
import {AuthService} from "../../services/auth.service";
import {BulkService} from "../../services/bulk.service";
import {CustomTasksService} from "../../services/custom-tasks.service";
import {EventListService} from "../../services/event-list.service";
import {EventManagementService} from "../../services/event-management.service";
import {EventNodesService} from "../../services/event-nodes.service";
import {FdrService} from "../../services/fdr.service";
import {MqttSubscriptionService} from "../../services/mqtt-subscription.service";
import {NodesFormatService} from "../../services/nodes-format.service";
import {RefDataService} from "../../services/ref-data.service";
import {SharedService} from "../../services/shared.service";
import {UserService} from "../../services/user.service";
import {CancelEventModalComponent} from "../cancel-event-modal/cancel-event-modal.component";
import {HierarchySelectorComponent} from "../hierarchy-selector/hierarchy-selector.component";
import {CustomModalWrapper} from "../modals/enel-modal/enel-modal.component";
import {EventExtendModalComponent} from "../modals/event-extend-modal/event-extend-modal.component";
import {ObligationModalComponent} from "../modals/obligation-modal/obligation-modal.component";
import {RefreshBaselinesModalComponent} from "../modals/refresh-baselines-modal/refresh-baselines-modal.component";

More(Highcharts);
Tree(Highcharts);
Heatmap(Highcharts);
Timeline(Highcharts);

export enum BulkActionType {
  none,
  activate,
  end,
  add,
  cancel,
  update,
  doNow,
  eventCancel,
  endCalcs,
  refreshBaseline,
}

@Component({
  selector: "app-aggregate-view",
  templateUrl: "./aggregate-view.component.pug",
  styleUrls: ["./aggregate-view.component.scss"],
  providers: [EventNodesService],
})
export class AggregateViewComponent implements OnInit {
  eventsLoaded: boolean = false;
  CTS: CustomTasksService;
  groupedNodes: GroupedEventNodes;
  nodesToEventMap;
  customTasksMap;
  NFS: NodesFormatService;
  hideDuringChange = false;

  @Input()
  private isTraining: boolean = false;

  constructor(
    private fdrService: FdrService,
    private router: Router,
    private sharedService: SharedService,
    private eventManagementService: EventManagementService,
    private authService: AuthService,
    private listService: EventListService,
    private epsMqttService: MqttService,
    private userService: UserService,
    private toastr: ToastrService,
    private RDS: RefDataService,
    private BS: BulkService,
    private ENS: EventNodesService
  ) {
    this.NFS = new NodesFormatService();
    this.CTS = new CustomTasksService();

    this.listService.training = this.isTraining;

    const {eventNodeUpdate$, nodesToEventMap$} = this.ENS;
    eventNodeUpdate$.pipe().subscribe((resp) => {
      this.handleNodeUpdate(resp);
    });
    nodesToEventMap$.pipe().subscribe((resp) => {
      this.handleNewNodesList(resp);
    });

    const { unfilteredActiveEvents$, cutomTasksUpdate$ } = this.listService;
    unfilteredActiveEvents$.pipe().subscribe((resp) => {
      this.handleEventListUpdate(resp);
    });
    cutomTasksUpdate$.pipe().subscribe((resp) => {
      this.handleCustomTasksUpdate(resp);
    });
  }

  @ViewChild(HierarchySelectorComponent, { static: false })
  hierarchySelectorElement: HierarchySelectorComponent;
  @ViewChild(EnelTabsComponent, { static: false })
  enelTabsElement: EnelTabsComponent;

  private ngUnsubscribe: Subject<any> = new Subject();

  static staticMe;

  Tabs = Object.freeze({
    events: 1,
    nodes: 2,
    tasks: 3,
    heatmap: 4,
  });

  //Bulk activate and end
  BulkActionType = Object.freeze({
    none: 0,
    activate: 1,
    end: 2,
    add: 3,
    cancel: 4,
    update: 5,
    doNow: 6,
    eventCancel: 7,
    endCalcs: 8,
    refreshBaseline: 9,
  });

  bulkActionType: BulkActionType = this.BulkActionType.none;

  eventsFiltered: boolean = false;
  filteredEvents = [];
  workflowStates = [];
  workflowStateInfo = null;
  performanceData = [];
  selectedEventGroup = -1;
  nodesLoaded: boolean = false;
  activeEvents;
  nodePerformanceSub: MqttSubscriptionService;
  eventIds;
  //This is just to keep track of which group to grab if a new group is added via MQTT and their order is shifted around
  selectedGroupId = "";

  //Aggregate summary
  totalObligation: number = 0;
  totalDemand: number = 0;
  averagePerformance: number = 0;
  totalRegistrations: number = 0;

  //Bulk actions
  selectingEvents: boolean;
  selectAllChecked: boolean;

  showBulkActivate: boolean;
  showBulkEventCancel: boolean;
  showBulkEnd: boolean;
  showBulkAdd: boolean;
  showBulkCancel: boolean;
  showBulkUpdate: boolean;
  showBulkDoNow: boolean;
  showBulkEndCalcs: boolean;
  allowBulkEndCalcs: boolean = false;
  allowRefreshBaselines: boolean = false;
  selectedBulkTemplate: string = null;
  canAutoAssign = true;
  //Performance subscriptions
  currentEventSubs = [];
  performanceTimeoutBuffer: boolean = false;
  eventPerformanceSubscribe$;
  perfDataTimeout;

  currentEventIds;
  nodesToWatch;
  nodesSubscription: MqttSubscriptionService;

  bulkEvents = [];
  bulkTasks = [];
  params = {};
  endCalcNodes = [];
  workflowStatusesMap;
  createableTemplates;
  registrationTypesMap;
  createableFilterTypesMap;
  createableFilterTypes;

  hierarchySelectorData = {
    productOptional: false,
    slim: true,
  };

  tabData = [
    {
      name: "Events",
      value: this.Tabs.events,
    },
    {
      name: "Event Nodes",
      value: this.Tabs.nodes,
    },
    {
      name: "Custom Tasks",
      value: this.Tabs.tasks,
    },
    {
      name: "Heatmap",
      value: this.Tabs.heatmap,
    },
  ];

  bulkActionData;

  ngOnInit() {
    //Set moment on the window so highcharts can find it
    window["moment"] = Moment;

    const controller = this;

    this.RDS.getControlTypesMap().subscribe();
    this.RDS.getWorkflowStatusesMap().subscribe((resp) => {
      this.workflowStatusesMap = resp;
    });
    this.RDS.getCreateableTemplates().subscribe((resp) => {
      this.createableTemplates = resp;
    });
    this.RDS.getRegistrationTypesMap().subscribe((resp) => {
      this.registrationTypesMap = resp;
    });
    this.RDS.getCreateableFilterTypesMap().subscribe((resp) => {
      this.createableFilterTypesMap = resp;
    });
    this.RDS.getCreateableFilterTypes().subscribe((resp) => {
      this.createableFilterTypes = resp;
    });
    this.RDS.getWorkflowAttributes().subscribe((resp) => {
      this.workflowStates = resp;
      this.eventManagementService
        .get("/v1/workflow_state", {})
        .pipe()
        .subscribe((workflow) => {
          controller.workflowStateInfo = workflow.data;
          controller.workflowStates.forEach((wfs) => {
            workflow.data.forEach((state) => {
              if (
                wfs.included_attribute_name &&
                wfs.included_attribute_name.toLowerCase() ===
                  state.included_variable_name.toLowerCase()
              ) {
                wfs.sequence = state.sequence;
              }
            });
          });
        });
    });

    this.bulkActionData = {
      events: [
        {
          buttonText: "Cancel Event",
          isEnabled: function () {
            return controller.showBulkEventCancel;
          },
          isAllowed: function () {
            return true;
          },
          bulkActionType: controller.BulkActionType.eventCancel,
        },
        {
          buttonText: "Duration",
          isEnabled: function () {
            return controller.showBulkEnd;
          },
          isAllowed: function () {
            return true;
          },
          bulkActionType: controller.BulkActionType.end,
        },
        {
          buttonText: "End Calculations",
          isEnabled: function () {
            return controller.showBulkEndCalcs;
          },
          isAllowed: function () {
            return controller.allowBulkEndCalcs;
          },
          bulkActionType: controller.BulkActionType.endCalcs,
        },
        {
          buttonText: "Refresh Baselines",
          isEnabled: function () {
            return true;
          },
          isAllowed: function () {
            return true;
          },
          bulkActionType: controller.BulkActionType.refreshBaseline,
        },
      ],
    };
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    clearTimeout(this.perfDataTimeout);
  }

  getEventNodes() {
    //console.log('getNodes from Agg View')
    this.ENS.getNodes(this.eventIDs);
  }

  getCustomTasks(refresh = false) {
    this.CTS.getCustomTasks(this.eventIDs, refresh)
      .pipe()
      .subscribe((resp) => {
        this.customTasksMap = resp;
        this.filteredEvents[this.selectedEventGroup].custom_tasks = [];
        this.filteredEvents[this.selectedEventGroup].events.forEach((event) => {
          event.custom_tasks = this.customTasksMap[event.event_id] || [];
          this.CTS.updateCustomTaskProp(event).then(() => {
            this.filteredEvents[this.selectedEventGroup].custom_tasks = [
              ...this.filteredEvents[this.selectedEventGroup].custom_tasks,
              ...event.custom_tasks,
            ];
            this.setCustomTasks();
          });
        });
      });
  }

  //region event nodes

  handleNewNodesList(data) {
    this.nodesToEventMap = data;
    this.nodesLoaded = true;
  }
  handleNodeUpdate(data) {
    this.getEventNodes();
  }
  //endregion

  handleEventListUpdate(data) {
    this.eventsLoaded = true;
    this.activeEvents = data;
    this.filterEvents();
  }

  handleCustomTasksUpdate(data) {
    data.cancelled = data.cancelled === undefined ? null : data.cancelled;
    let event = _.find(
      this.filteredEvents[this.selectedEventGroup]?.events,
      (e) => {
        return e.event_id === data.event_id;
      }
    );
    if (event) {
      this.CTS.addTasksToEvent(event, data).then((tasks) => {
        this.getCustomTasks(true);
      });
    }
  }

  // onHeatMapInit(){
  //   this.getEventNodes();
  // }

  onTimeLineToggle(e) {
    if (!e.collapsed) {
      this.getEventNodes();
    }
  }

  onPerfChartToggle(e) {
    if (!e.collapsed) {
      this.getPerformanceData();
    } else {
      clearTimeout(this.perfDataTimeout);
      this.performanceTimeoutBuffer = false;
    }
  }

  onCustomTasksToggle(e) {}

  getPerformanceData() {
    let controller = this;
    if (controller.performanceTimeoutBuffer === false) {
      controller.performanceTimeoutBuffer = true;
      controller.eventManagementService
        .post(
          "/v1/event_performance",
          { event_ids: this.eventIDs, performance_aggregate_type: "PORTFOLIO" },
          {}
        )
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (resp) => {
            controller.performanceData = resp.data;
            controller.perfDataTimeout = setTimeout(function () {
              controller.performanceTimeoutBuffer = false;
            }, 30000);
          },
          (err) => {}
        );
    }
  }

  clearSelectedEvents() {
    this.selectedEventGroup = -1;
    this.selectedGroupId = "";
    this.filteredEvents = [];
    this.eventsFiltered = false;
  }

  filterEvents(update = true) {
    const controller = this;
    controller.filteredEvents = [];
    const selector = controller.hierarchySelectorElement;
    if (!selector) return;
    let done = _.after(this.activeEvents.length, () => {
      //To make the filtering inception below easier on my feeble brain
      let actuallyFiltered = [];

      //Separate the events into groups based on their start times and groups
      //Try not to look directly at this
      controller.filteredEvents = _.chain(controller.filteredEvents)
        .groupBy("event_start_dttm_utc")
        .map((groups, time) => ({
          start_time: time,
          typeGroups: _.chain(groups)
            .groupBy("event_action_type_display_label")
            .map((events, type) => ({
              start_time: time,
              event_type: type,
              events: events,
              custom_tasks: [],
            }))
            .forEach((res) => {
              actuallyFiltered.push(res);
            })
            .value(),
        }))
        .value();

      controller.filteredEvents = actuallyFiltered;

      if (!update) {
        controller.selectedEventGroup =
          controller.filteredEvents.length === 1 ? 0 : -1;
        //Gotta set that group if we're auto-selecting the first.
        if (controller.selectedEventGroup === 0) controller.setGroup(0);
      } else {
        let index = 0;
        let foundGroup = false;

        let done = _.after(controller.filteredEvents.length, () => {
          controller.setGroup(controller.selectedEventGroup);
        });

        _.forEach(controller.filteredEvents, (g) => {
          let littleDone = _.after(g.events.length, () => {
            done();
          });

          _.forEach(g.events, (e) => {
            if (controller.selectedGroupId === e.event_id) {
              foundGroup = true;
              controller.selectedEventGroup = index;
            }
            littleDone();
          });

          index++;
        });
      }
      if (controller.filteredEvents.length === 0)
        controller.selectedEventGroup = -1;

      //Update the params for the current page if necessary
      this.eventsFiltered = true;

      this.params = {
        view: "aggregated",
        operator: selector.selectedOperator.id,
        program: selector.selectedProgram.id,
        product: selector.selectedProduct.id,
      };

      const paramRoute = this.isTraining ? '/training' : '';
      this.sharedService.updateParams(paramRoute, this.params);
    });

    _.forEach(this.activeEvents, (ev) => {
      if (
        selector.selectedProduct?.id &&
        ev.product_id === selector.selectedProduct.id
      ) {
        controller.filteredEvents.push(ev);
      }
      done();
    });

    if (this.activeEvents.length === 0) this.eventsFiltered = true;
  }

  setCustomTasks() {
    let numCustomTasksAvailable = 0;

    if (this.filteredEvents[this.selectedEventGroup].custom_tasks) {
      _.forEach(
        this.filteredEvents[this.selectedEventGroup].custom_tasks,
        (task) => {
          if (!task.completed_time && !task.cancelled) {
            numCustomTasksAvailable++;
          }

          task.event_node_count = Object.keys(task.event_nodes).length;
          task.has_event_nodes = task.event_node_count > 0;
        }
      );
    }

    if (numCustomTasksAvailable > 1) {
      this.showBulkCancel = true;
      this.showBulkUpdate = true;
      this.showBulkDoNow = true;
    } else {
      this.showBulkCancel = false;
      this.showBulkUpdate = false;
      this.showBulkDoNow = false;
    }

    this.filteredEvents[this.selectedEventGroup].custom_tasks = _.sortBy(
      this.filteredEvents[this.selectedEventGroup].custom_tasks,
      "scheduled_time",
      "portfolio_name"
    );
  }

  eventIDs;
  setGroup(groupNum) {
    this.hideDuringChange = groupNum !== this.selectedEventGroup;

    if (groupNum === -1) groupNum = this.selectedEventGroup;

    if (groupNum !== this.selectedEventGroup) this.nodesLoaded = false;
    this.resetEventSelections();
    this.selectedEventGroup = groupNum;

    this.eventIDs = [];

    if (this.filteredEvents[this.selectedEventGroup]) {
      this.selectedGroupId =
        this.filteredEvents[this.selectedEventGroup].events[0].event_id;
      const controller = this;
      let customTaskParams = "";
      let numEventsAvailable = 0;
      let numWithPending = 0;
      let numCustomTasksAvailable = 0;

      controller.totalDemand = 0;
      controller.totalObligation = 0;
      controller.averagePerformance = 0;
      controller.totalRegistrations = 0;

      let doneWithEvents = _.after(
        this.filteredEvents[this.selectedEventGroup].events.length,
        () => {
          this.getCustomTasks();
        }
      );

      this.checkShowBulkEndCalcs();

      let i = 0;
      //Loop through our events and custom tasks to see what bulk actions are available
      _.forEach(
        this.filteredEvents[this.selectedEventGroup].events,
        (event) => {
          this.eventIDs.push(event.event_id);
          customTaskParams += "event_ids[" + i + "]=" + event.event_id;

          if (i < this.filteredEvents[this.selectedEventGroup].events.length)
            customTaskParams += "&";

          i++;
          //Add aggregated summary values
          controller.totalDemand += event.last_current_performance_value;
          controller.totalObligation += event.obligation;
          controller.averagePerformance += event.event_performance_percent;
          controller.totalRegistrations += event.num_participating_units;

          if (
            moment(event.event_end_dttm_utc).isAfter(moment()) ||
            event.event_end_dttm_utc === null
          ) {
            numEventsAvailable++;
            numWithPending +=
              event.event_node_statistics.pending_event_nodes.length;
          }

          doneWithEvents();
        }
      );

      //Get average aggregated summary values
      controller.averagePerformance /=
        this.filteredEvents[this.selectedEventGroup].events.length;

      //I separated all of the actions out into their own booleans in case we want to get more strict with specific actions later
      if (numEventsAvailable > 1) {
        this.showBulkEnd = true;
        this.showBulkAdd = true;
        this.showBulkEventCancel = true;
      } else {
        this.showBulkAdd = false;
        this.showBulkEnd = false;
        this.showBulkEventCancel = false;
      }

      this.showBulkActivate = numWithPending > 1;
    }
    setTimeout(() => {
      this.hideDuringChange = false;
    });
  }

  checkShowBulkEndCalcs() {
    this.showBulkEndCalcs = false;
    const controller = this;
    const group = this.filteredEvents[this.selectedEventGroup];
    const prod = this.hierarchySelectorElement.selectedProduct;

    group?.events?.forEach((ev: EPSEvent) => {
      if (prod && prod.dispatch_conf && prod.dispatch_conf.post_bonus_minutes) {
        this.allowBulkEndCalcs = true;

        if (
          !ev.cancelled &&
          ev.event_end_dttm_utc &&
          moment().isSameOrAfter(moment(ev.event_end_dttm_utc)) &&
          moment().isSameOrBefore(
            moment(ev.event_end_dttm_utc).add(
              prod.dispatch_conf.post_bonus_minutes,
              "minutes"
            )
          )
        ) {
          this.showBulkEndCalcs = true;
          // let  nodesToTest = [];
          // if(controller.nodesToEventMap[ev.event_id].activeNodes) nodesToTest = [...controller.nodesToEventMap[ev.event_id].activeNodes];
          // if(controller.nodesToEventMap[ev.event_id].completedNodes) nodesToTest = [...nodesToTest, ...controller.nodesToEventMap[ev.event_id].completedNodes];
          //
          // nodesToTest.forEach((node: EventNode) => {
          //   if(!node.performance_end_dttm_utc || (node.performance_end_dttm_utc.length && moment(node.performance_end_dttm_utc).isAfter(moment()))){
          //     this.showBulkEndCalcs = true;
          //   }
          // });
        }
      }
    });

    setTimeout(() => {
      this.checkShowBulkEndCalcs();
    }, 1000);
  }

  goToEventDetail(event) {
    if(!this.selectingEvents) {
      const eventSnippetUrl = this.isTraining ? '/training/event/' : '/event/';
      const url = window.location.origin + eventSnippetUrl+ event.event_id;
      window.open(url, '_blank');
    }
  }

  ////////////////////////////////////////////////
  ///// Bulk methods /////
  ////////////////////////////////////////////////

  autoAssign() {
    //this.filteredEvents[this.selectedEventGroup].events.
    const controller = this;
    const user = this.userService.user;
    this.canAutoAssign = false;
    const body = {
      username: user.username,
      event_start_time:
        this.filteredEvents[this.selectedEventGroup].events[0]
          .event_start_dttm_utc,
      event_ids: this.eventIDs,
    };
    this.eventManagementService
      .post("/v1/event_nodes/auto_assign", body, {})
      .pipe()
      .subscribe(
        (response) => {
          const num = response.data.length;
          let msg =
            num > 0
              ? num + " Registration(s) have been assigned to your user"
              : "There are no additional registrations to assign";
          controller.toastr.success(msg);
        },
        (error) => {
          controller.toastr.error("Error assigning registrations");
          this.canAutoAssign = true;
          console.log(error);
        }
      );
  }

  bulkContinueDisabled(): boolean {
    if (
      this.bulkActionType === this.BulkActionType.doNow ||
      this.bulkActionType === this.BulkActionType.update
    ) {
      return this.selectedBulkTemplate === null;
    }
    if (this.bulkActionType === this.BulkActionType.end) {
      return false;
    }
    return !this.filteredEvents[this.selectedEventGroup].events.some(event => event.selectedInGrid);
  }

  //region bulk selection
  clickBulkAction(type): void {
    this.bulkActionType = type;

    if (this.bulkActionType === this.BulkActionType.add) {
      this.bulkAction();
    } else {
      this.selectingEvents = true;
    }
  }

  cancelBulkAction(): void {
    this.resetEventSelections();
    this.selectAllChecked = false;
    this.selectingEvents = false;
    this.bulkActionType = this.BulkActionType.none;
  }

  onChangeTaskSelections(b) {
    this.selectingEvents = b;
  }

  bulkAction() {
    switch (this.bulkActionType) {
      case this.BulkActionType.activate:
        this.bulkActivate();
        break;
      case this.BulkActionType.end:
        this.bulkEnd();
        break;
      case this.BulkActionType.eventCancel:
        this.bulkCancelEvent();
        break;
      case this.BulkActionType.endCalcs:
        this.bulkEndCalcs();
        break;
      case this.BulkActionType.refreshBaseline:
        this.refreshBaselines();
        break;
    }
  }

  getSelectedTasks(idOnly: boolean, next) {
    let selected = [];

    //After getting the selected IDs, send them to our next function
    const doneSelecting = _.after(
      this.filteredEvents[this.selectedEventGroup].custom_tasks.length,
      () => {
        next(selected);
      }
    );

    //Loop through all of the custom tasks we're looking at and see which ones are selected
    this.filteredEvents[this.selectedEventGroup].custom_tasks.forEach(
      (task) => {
        if (task.selectedInGrid) {
          if (idOnly) selected.push(task.custom_task_id);
          else selected.push(task);
        }
        doneSelecting();
      }
    );
  }

  /*  bulkTaskOperation(type: string) {
    let title;
    let controller = this;

    switch (type) {
      case 'add':
        title = 'Bulk Add Custom Tasks';
        break;
      case 'update':
        title = 'Update Custom Tasks';
        break;
      case 'cancel':
        title = 'Cancel Events';
        break;
      case 'endCalcs':
        title = 'End Calculations';
        break;
      case 'donow':
        title = 'Send Now';
        break;
    }

    this.sharedService.activateModal({
      headerText: title,
      bodyText: "",
      buttonText: 'Continue',
      cancelText: 'Cancel',
      allowCancel: true,
      password: 'ennel1',
      confirmFunction: function () {
        setTimeout(function () {
          switch (type) {
            case 'add':
              controller.bulkAdd();
              break;
            case 'update':
              controller.bulkUpdate();
              break;
            case 'cancel':
              controller.bulkCancel();
              break;
            case 'donow':
              controller.bulkDoNow();
              break;
          }

        });
      }});

  }*/

  selectAllEvents(): void {
    if (!this.selectAllChecked) {
      //If we're on the Events tab and the event is selectable, select it
      if (this.enelTabsElement.currentTab === this.Tabs.events) {
        //For every event in our list...
        _.forEach(
          this.filteredEvents[this.selectedEventGroup].events,
          (event) => {
            if (this.isEventSelectable(event)) {
              event.selectedInGrid = true;
            }
          }
        );
      }
    } else {
      this.resetEventSelections();
    }
  }

  checkIfAllEventsSelected(): void {
    if (this.enelTabsElement.currentTab === this.Tabs.events) {
      this.selectAllChecked = this.filteredEvents[
        this.selectedEventGroup
      ].events.every((n) => {
        return !this.isEventSelectable(n) || n.selectedInGrid;
      });
    }
  }

  resetEventSelections(): void {
    this.selectAllChecked = false;
    _.forEach(this.filteredEvents, (group) => {
      _.forEach(group.events, (event) => {
        event.selectedInGrid = false;
      });
      _.forEach(group.custom_tasks, (task) => {
        task.selectedInGrid = false;
      });
    });
  }

  isEventSelectable(event: EPSEvent): boolean {
    if (this.bulkActionType == this.BulkActionType.refreshBaseline) {
      return true;
    }

    if (this.bulkActionType == this.BulkActionType.endCalcs) {
      if (
        event.event_end_dttm_utc &&
        moment().isSameOrAfter(moment(event.event_end_dttm_utc))
      ) {
        // let nodesToTest = [];
        // if(this.nodesToEventMap[event.event_id].activeNodes) nodesToTest = [...this.nodesToEventMap[event.event_id].activeNodes];
        // if(this.nodesToEventMap[event.event_id].completedNodes) nodesToTest = [...nodesToTest, ...this.nodesToEventMap[event.event_id].completedNodes];
        // if(_.findIndex(nodesToTest, function(node) { return !node.performance_end_dttm_utc || (node.performance_end_dttm_utc.length && moment(node.performance_end_dttm_utc).isAfter(moment()))}) > -1) {
        //   return true;
        // }
        return true;
      } else {
        return false;
      }
    }

    if (
      event.event_end_dttm_utc === null ||
      moment(event.event_end_dttm_utc).isAfter(moment())
    ) {
      //If we're activating, don't allow an event without pending nodes to be selected
      if (
        this.bulkActionType === this.BulkActionType.activate &&
        !this.nodesToEventMap[event.event_id].event_node_statistics
          .pending_event_nodes.length
      )
        return false;

      return true;
    }
    return false;
  }

  //endregion

  //region bulk event

  checkIfAnyEventsSelected() {
    let anySelected = false;
    this.filteredEvents[this.selectedEventGroup].events.forEach((event) => {
      if (event.selectedInGrid) {
        anySelected = true;
      }
    });
    return anySelected;
  }

  refreshBaselines() {
    const controller = this;
    const eventIds = [];
    const startTimes = [];
    const endTimes = [];

    const events =
      controller.filteredEvents[controller.selectedEventGroup].events;
    events.forEach((ev) => {
      if (ev.selectedInGrid) {
        console.log(ev);
        eventIds.push(ev.event_id);
        startTimes.push(ev.event_start_dttm_utc);
        endTimes.push(ev.event_end_dttm_utc);
      }
    });

    if (events.length < 1) {
      return;
    }

    let timePickerData = new ModalDatePickerData(
      null,
      "Update Notification",
      moment().toISOString(),
      endTimes[0],
      0,
      true,
      false,
      false,
      false
    );

    controller.sharedService.activateModal({
      headerText: "Refresh Baselines",
      bodyText:
        "This action will allow to recalculate the baseline adjustment for this group of dispatches.",
      allowCancel: true,
      customContent: new CustomModalWrapper(RefreshBaselinesModalComponent, {
        smallBodyText:
          "Please note if this action is executed when data is no longer available (around 8 hours after event end time) the baseline refresh may not be reflected, but changes to the notification time will be logged.",
        passwordRequired: true,
        password: "ennel1",
        program_time_zone:
          controller.hierarchySelectorElement.selectedProduct.timezone,
        showTimePicker:
          controller.hierarchySelectorElement.selectedProduct
            .collect_notification_time,
        timePickerData: timePickerData,
        confirmFunction: function (notificationDate) {
          const params = notificationDate.isValid()
            ? { notification_time: notificationDate.toISOString() }
            : {};
          controller.eventManagementService
            .post("/v1/events/refresh_baselines", eventIds, params)
            .pipe(takeUntil(controller.ngUnsubscribe))
            .subscribe(
              (response) => {
                controller.sharedService.popSuccess("Baseline Refreshed.");
                this.close();
                controller.cancelBulkAction();
              },
              (err) => {
                controller.sharedService.popError(
                  "Error occurred for refresh baseline."
                );
                console.dir("Error refreshing baseline: ");
                console.dir(err);
              }
            );
        },
      }),
    });
  }

  bulkEndCalcs() {
    const controller = this;
    const nodesToUpdate = [];
    const eventIds = [];

    controller.filteredEvents[controller.selectedEventGroup].events.forEach(
      (ev) => {
        if (ev.selectedInGrid) {
          eventIds.push(ev.event_id);
        }
      }
    );

    controller.sharedService.activateModal({
      headerText: "End Calculations",
      bodyText: "Ending calculations. Are you sure?",
      buttonText: "End Calculations",
      cancelText: "No",
      allowCancel: true,
      confirmFunction: function () {
        controller.eventManagementService
          .post(
            "/v1/events/end_event_calculations",
            { event_ids: eventIds },
            {
              product_id:
                controller.hierarchySelectorElement.selectedProduct.id,
            }
          )
          .pipe()
          .subscribe(
            (resp) => {
              if (resp.code == 207) {
                controller.sharedService.popSuccess(
                  "Some of the nodes were not eligible to update.  See console for the list of updated nodes."
                );
                console.log("Updated Node Ids: " + resp.data);
              } else if (resp.code == 400) {
                controller.sharedService.popSuccess(
                  "None of the nodes were eligible to update."
                );
              } else {
                controller.sharedService.popSuccess("Calculations ended.");
              }
              controller.cancelBulkAction();
            },
            (err) => {
              console.dir("Error ending calculations");
              console.dir(err);
            }
          );
      },
    });
  }

  bulkCancelEvent(): void {
    const controller = this;
    let event_ids = [];

    this.bulkEvents = [];
    let cancellingAll = false;
    let nodesToSend = {};

    this.filteredEvents[this.selectedEventGroup].events.forEach((event) => {
      if (event.selectedInGrid) {
        controller.bulkEvents.push(event);
        event_ids.push(event.event_id);
        //nodesToSend[event.event_id] = this.nodesToEventMap[event.event_id];
      }
    });

    if (
      this.filteredEvents[this.selectedEventGroup].events.length ===
      event_ids.length
    )
      cancellingAll = true;

    if (this.bulkEvents.length) {
      this.sharedService.activateModal({
        headerText: "Cancel Events",
        bodyText: "Cancelling " + this.bulkEvents.length + " Events.",
        customContent: new CustomModalWrapper(CancelEventModalComponent, {
          style: {
            width: "30%",
            "min-width": "350px",
          },
          password: "ennel1",
          nodes: this.nodesToEventMap,
          events: event_ids,
          onCancel: (notifyNodes) => {
            let cancelBody = { event_ids: event_ids };
            if (notifyNodes) {
              cancelBody["cancel_notification"] = true;
            }
            this.eventManagementService
              .post("/v1/events/cancel", cancelBody, {})
              .pipe()
              .subscribe(
                (response) => {
                  controller.sharedService.popSuccess(
                    "Successfully cancelled Events. Updates may take up to 1 minute to display on this page."
                  );
                  controller.cancelBulkAction();

                  if (cancellingAll) controller.clearSelectedEvents();
                },
                (error) => {
                  controller.sharedService.popError("Failed to cancel Events!");
                  controller.cancelBulkAction();
                }
              );
          },
        }),
      });
    } else {
      controller.sharedService.popError("Must select at least one event!");
    }
  }

  bulkActivate(): void {
    const controller = this;
    this.bulkEvents = [];
    let portfoliosToSend = [];

    this.filteredEvents[this.selectedEventGroup].events.forEach((event) => {
      if (event.selectedInGrid) {
        controller.bulkEvents.push(event);
      }
    });

    if (this.bulkEvents.length) {
      let start =
        controller.filteredEvents[controller.selectedEventGroup].events[0]
          .event_start_dttm_utc;

      this.sharedService.activateModal({
        headerText: "Ramp Up Activation",
        bodyText:
          "Ramping up selection for " + this.bulkEvents.length + " Events.",
        allowCancel: true,
        customContent: new CustomModalWrapper(ObligationModalComponent, {
          buttonText: "Bulk Activate",
          confirmFunction: function (obligation) {
            controller.sharedService.activateLoader(
              new LoaderActivator(
                new Promise(function (resolve, reject) {
                  const afterPortfolios = _.after(
                    controller.bulkEvents.length,
                    () => {
                      ///////////
                      // STEP 1: Select new registrations
                      //////////
                      controller.eventManagementService
                        .post(
                          "/v1/select_registrations",
                          {
                            product_id:
                              controller.hierarchySelectorElement
                                .selectedProduct.id,
                            portfolios: portfoliosToSend,
                            event_type:
                              controller.filteredEvents[
                                controller.selectedEventGroup
                              ].event_type,
                            start_time: start,
                            event_start_time: start,
                          },
                          {}
                        )
                        .pipe(takeUntil(controller.ngUnsubscribe))
                        .subscribe(
                          (response) => {
                            ///////////
                            // STEP 3: Report errors or success
                            //////////
                            let allErrors = [];
                            const doneActivating = _.after(
                              response.data.length,
                              () => {
                                if (allErrors.length) {
                                  resolve({
                                    message:
                                      "Failed to ramp up events while activating nodes. Check the developer console for details.",
                                    error: allErrors,
                                  });
                                } else {
                                  resolve({
                                    message:
                                      "Successfully updated event end times. Updates may take up to 1 minute to display on this page.",
                                  });
                                }
                              }
                            );

                            ///////////
                            // STEP 2: Activate new event nodes, grouped by portfolio
                            //////////
                            _.forEach(response.data, (port) => {
                              let nodesToActivate = [];
                              let nodeErrors = [];
                              let event = _.find(controller.bulkEvents, (e) => {
                                return e.portfolio_id === port.portfolio_id;
                              });

                              ///////////
                              // STEP 2.2: done getting the nodes for this portfolio. activate them
                              //////////
                              const doneGettingNodes = _.after(
                                port.selected.length,
                                () => {
                                  //We couldn't find one or more event node in the selected events' pending node lists
                                  if (nodeErrors.length) {
                                    resolve({
                                      message:
                                        "Failed to ramp up events while getting pending nodes. Check the developer console for details.",
                                      error: nodeErrors,
                                    });
                                  } else {
                                    controller.eventManagementService
                                      .put(
                                        "/v1/activate",
                                        {
                                          event_nodes: nodesToActivate,
                                          start_time: start,
                                          end_time: event.event_end_dttm_utc,
                                        },
                                        {}
                                      )
                                      .pipe(takeUntil(controller.ngUnsubscribe))
                                      .subscribe(
                                        (response) => {
                                          doneActivating();
                                        },
                                        (error) => {
                                          allErrors.push(error);
                                          doneActivating();
                                        }
                                      );
                                  }
                                }
                              );

                              ///////////
                              // STEP 2.1: translate reg id to event node id
                              //////////
                              _.forEach(port.selected, (reg) => {
                                let node: EventNode = _.find(
                                  controller.nodesToEventMap[event.event_id]
                                    .pendingNodes,
                                  (pNode) => {
                                    return pNode.registration_id === reg.id;
                                  }
                                );

                                //Found the node, so add it to the list we want to activate
                                if (node) {
                                  nodesToActivate.push(node.event_node_id);
                                  doneGettingNodes();
                                } else {
                                  nodeErrors.push(
                                    "Could not find a pending node with reg id: " +
                                      reg.id +
                                      " in the event with id: " +
                                      event.event_id
                                  );
                                  doneGettingNodes();
                                }
                              });
                            });
                          },
                          (error) => {
                            resolve({
                              message:
                                "Failed to ramp up events while selecting registrations.",
                              error: error,
                            });
                          }
                        );
                    }
                  );

                  //Create the portfolio objects to send in
                  _.forEach(controller.bulkEvents, (ev) => {
                    portfoliosToSend.push({
                      portfolio_id: ev.portfolio_id,
                      obligation: controller.sharedService.convertToKW(
                        obligation,
                        controller.sharedService.getPrezUOM(
                          controller.hierarchySelectorElement.selectedProduct
                        )
                      ),
                      ignored_registrations: _.reduce(
                        controller.nodesToEventMap[ev.event_id].activeNodes,
                        function (result, node) {
                          return result.concat(node.registration_id);
                        },
                        []
                      ),
                    });

                    afterPortfolios();
                  });
                }),
                function () {
                  controller.cancelBulkAction();
                  controller.sharedService.popSuccess(
                    "Successfully ramped up events. Updates may take up to 1 minute to display on this page."
                  );
                },
                function (error) {
                  controller.cancelBulkAction();
                  console.dir(error.error);
                },
                false,
                "Ramping Up Events",
                false,
                true
              )
            );
          },
        }),
      });
    } else {
      controller.sharedService.popError("Must select at least one event!");
    }
  }

  bulkEnd(): void {
    const controller = this;

    this.bulkEvents = [];

    this.filteredEvents[this.selectedEventGroup].events.forEach((event) => {
      if (event.selectedInGrid) {
        controller.bulkEvents.push({ event: event, obligation: null });
      }
    });

    if (!this.bulkEvents.length) {
      this.sharedService.popError('Must select at least one event');
      return;
    }

    let start =
      this.filteredEvents[this.selectedEventGroup].events[0]
        .event_start_dttm_utc;
    let timePickerDataEnd = new ModalDatePickerData(
      this.filteredEvents[
        this.selectedEventGroup
      ].events[0].event_end_dttm_utc,
      "End",
      start,
      moment(start)
        .add(
          this.hierarchySelectorElement.selectedProduct.max_event_duration +
            43200000,
          "ms"
        )
        .toISOString(),
      this.hierarchySelectorElement.selectedProduct.max_event_duration,
      false,
      false,
      true,
      false
    );

    this.sharedService.activateModal({
      headerText: "Update Event End Time",
      bodyText: "Updating " + this.bulkEvents.length + " events.",
      customContent: new CustomModalWrapper(EventExtendModalComponent, {
        program_time_zone:
          controller.hierarchySelectorElement.selectedProduct.timezone,
        product_uom: controller.sharedService.getPrezUOM(
          controller.hierarchySelectorElement.selectedProduct
        ),
        events: controller.bulkEvents,
        timePickerData: [timePickerDataEnd],
        buttonText: "Update End Time",

        confirmFunction: function (time, evData) {
          let body = _.map(evData, (e) => {
            let bd = { event_id: e.event.event_id };

            if (e.obligation)
              bd["obligation"] = controller.sharedService.convertToKW(
                e.obligation,
                this.product_uom
              );

            return bd;
          });

          controller.sharedService.activateLoader(
            new LoaderActivator(
              new Promise(function (resolve, reject) {
                resolve(
                  controller.eventManagementService.put(
                    "/v1/event/event_end_time/" + time[0],
                    body,
                    {}
                  )
                );
              }),
              function () {
                controller.cancelBulkAction();
                controller.sharedService.popSuccess(
                  "Successfully updated end times. Updates may take up to 1 minute to display on this page."
                );
              },
              function (error) {
                controller.cancelBulkAction();
                console.dir(error.error);
              },
              false,
              "Updating End Times",
              false,
              false
            )
          );
        },
      }),
    });
  }
  //endregion

  //region bulk custom task
  /* bulkDoNowStep(data): void {
    const controller = this;

    const reqObj = {
      event_nodes: data.eventNodes,
    };

    controller.sharedService.activateModal({
      headerText: "Triggering Workflow Step (" + data.workflowStepName + ')',
      bodyText: "Triggering workflow step for " + data.eventNodes.length + " event nodes. Are you sure?",
      buttonText: 'Trigger',
      cancelText: 'No',
      allowCancel: true,
      confirmFunction: function () {

        let path = '/v1/event_nodes/trigger/' + data.attribute + '/';

        controller.eventManagementService.put(path, reqObj, {}).pipe(takeUntil(controller.ngUnsubscribe)).subscribe(
          response => {
            controller.sharedService.popSuccess("Successfully updated Event Nodes");
            controller.cancelBulkAction();
          },
          error => {
            controller.sharedService.popError("Failed to update Event Node!");
            console.dir(error)
            controller.cancelBulkAction();
          });

      }
    });
  }*/

  /*bulkExclude(data): void {
    const controller = this;

    let reqObj = {
      event_nodes: data.eventNodes,
    };

    controller.sharedService.activateModal({
      headerText: "Exclude Workflow Step (" + data.workflowStepName + ')',
      bodyText: "Excluding workflow step for " + data.eventNodes.length + " event nodes. Are you sure?",
      warningText: (data.warnNextStepWillFire ? "Warning: excluding this step will result in the next step immediately firing. " : ""),
      buttonText: 'Exclude',
      cancelText: 'No',
      allowCancel: true,
      confirmFunction: function () {

        controller.eventManagementService.put('/v1/event_nodes/exclude/' + data.attribute, reqObj, {}).pipe(takeUntil(controller.ngUnsubscribe)).subscribe(
          response => {
            controller.sharedService.popSuccess("Successfully updated Event Nodes");
            controller.cancelBulkAction();
          },
          error => {
            controller.sharedService.popError("Failed to update Event Node!");
            console.dir(error);
            controller.cancelBulkAction();
          });
      }
    });
  }*/

  /*bulkRescheduleStep(data): void {
    const controller = this;

    const reqObj = {
      event_nodes: data.eventNodes,
    };

    let start = controller.filteredEvents[controller.selectedEventGroup].events[0].event_start_dttm_utc;

    let timePickerData = new ModalDatePickerData(null, '', moment().toISOString(), null, 0,true, false, false, false);

    this.sharedService.activateModal({
      headerText: 'Update Workflow Step (' + data.workflowStepInfo.name + ')',
      bodyText: "Updating workflow step for " + data.eventNodes.length + " event nodes. Are you sure?",
      customContent: new CustomModalWrapper(WorkflowStepEditModalComponent, {
        defaultTime: data.workflowStepInfo.time,
        automated: data.workflowStepInfo.automated,
        buttonText: 'Update',
        showInclude: data.showInclude,
        program_time_zone: this.hierarchySelectorElement.selectedProduct.timezone,
        confirmFunction: function(updateBody) {
          let path = '/v1/event_nodes/reschedule/' + data.attribute + '/' + updateBody.time;

          path += '?automate=' + updateBody.automated.toString();

          if(updateBody.include !== null && updateBody.include !== undefined) {
            path += '&include=' + updateBody.include.toString();
          }

          controller.eventManagementService.put(path, reqObj, {}).pipe(takeUntil(controller.ngUnsubscribe)).subscribe(
            response => {
              controller.sharedService.popSuccess("Successfully updated Event Nodes");
              controller.cancelBulkAction();
            },
            error => {
              controller.sharedService.popError("Failed to update Event Node!");
              console.dir(error)
              controller.cancelBulkAction();
            });

        },
        timePickerData: [timePickerData]
      })
    });
  }*/

  bulkDoNow(): void {
    this.eventManagementService.bulkDoNow(
      this.filteredEvents[this.selectedEventGroup].custom_tasks,
      this.ngUnsubscribe,
      () => {
        this.cancelBulkAction();
      }
    );
  }

  bulkCancel(): void {
    this.eventManagementService.bulkCancel(
      this.filteredEvents[this.selectedEventGroup].custom_tasks,
      this.ngUnsubscribe,
      () => {
        this.cancelBulkAction();
      }
    );
  }

  bulkUpdate(): void {
    this.eventManagementService.bulkUpdate(
      this.filteredEvents[this.selectedEventGroup].custom_tasks,
      this.ngUnsubscribe,
      this.hierarchySelectorElement.selectedProduct.max_event_duration,
      this.filteredEvents[this.selectedEventGroup].events[0]
        .event_start_dttm_utc,
      this.hierarchySelectorElement.selectedProduct.timezone,
      this.createableFilterTypes,
      this.createableTemplates,
      () => {
        this.cancelBulkAction();
      }
    );
  }

  bulkAdd(): void {
    this.BS.addCustomTasks(
      this.filteredEvents[this.selectedEventGroup].events,
      this.hierarchySelectorElement.selectedProduct
    )
      .then((resolved) => {
        this.cancelBulkAction();
      })
      .catch((rejected) => {
        this.cancelBulkAction();
      });
  }
  //endregion

  fixedDecimals(value, numDecimals = 3) {
    if (typeof value === "number") return value.toFixed(numDecimals);
    else return null;
  }
}
