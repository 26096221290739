import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Heatmap from 'highcharts/modules/heatmap';
import Tree from 'highcharts/modules/treemap';
import * as _ from 'lodash';
import { Moment } from 'moment';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import { Subject } from 'rxjs';
import { EPSEvent } from '../../../classes/event';
import { EventNode, GroupedEventNodes } from '../../../classes/event_node';
import { PaginationMeta } from '../../../classes/paginationMeta';
import { Product } from '../../../classes/product';
import { PaginatorComponent } from '../../../directives/paginator/paginator.component';
import { EventListService } from '../../../services/event-list.service';
import { EventManagementService } from '../../../services/event-management.service';
import { PaginatedNodeService } from '../../../services/paginated-node.service';
import { SharedService } from '../../../services/shared.service';
import { NodeListFilterComponent } from '../../node-list-filter/node-list-filter.component';





More(Highcharts);
Tree(Highcharts);
Heatmap(Highcharts);

export enum ConfirmationStatus {
  'Confirmed', 'Unconfirmed', 'Preauthorized'
}

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.pug',
  styleUrls: ['./heatmap.component.scss']
})


export class HeatmapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() events: EPSEvent[];
  @Input() product: Product;

  @ViewChild(PaginatorComponent, {static: false}) paginator: PaginatorComponent;
  @ViewChild(NodeListFilterComponent, {static: false}) nodeListFilter: NodeListFilterComponent;

  paginationMeta:PaginationMeta = new PaginationMeta();
  fetchingNodes = false;
  PNS: PaginatedNodeService;
  nodesToEventMap;
  nodesToWatch;

  selectedEvents = [];
  Highcharts = Highcharts;
  chart = null;
  updateChart:boolean = false
  eventsOver:boolean = false;
  showBy = 0;
  excludeContacted = false;
  excludeNoData = false;
  excludeCompleted = false;
  performingChecked = false;
  expectedCapacityChecked = false;
  capacityAboveBelow = 0;
  capacityAboveBelowKw = 20;
  performingBy = 0;
  performingAboveBelow = 0;
  performingAboveBelowPerCent = 20;
  productUOM = 'kW';
  maxCapacity = 0;
  minCapacity = 0;
  errorThreshold = 50;
  warningThreshold = 90;
  noEventsSelected = false;
  noEventsMessage = '';
  gettingData = true;
  eventEndTimeOut;
  getEventsTimeout;
  chartRefreshTimeout;
  firstDataLoad = true;
  logIt = true;

  //region chart options
  chartSizeOptions = [{label: 'Default', value: 'default-res'}, {label: '1366 x 768', value: 'laptop-res'}, {label: '1600 x 900', value: 'monitor-res'}, {label: '2560 x 1440', value: 'thunderbolt-res'}]
  selectedChartSize= 'default-res';
  chartResolutions = {
    'default-res':{'height': 400, 'width': 1120},
    'laptop-res':{'height': 768, 'width': 1366},
    'monitor-res':{'height': 900, 'width': 1600},
    'thunderbolt-res':{'height': 1440, 'width': 2560},
  }

  chartOptions = {
    chart: {
      borderWidth: 1,
      borderColor: '#d8d8d8',
      spacingBottom: 15,
      spacingTop: 10,
      spacingLeft: 10,
      spacingRight: 10,
      height: 400,
      width: 1120,
      style: {
        color: '#461e7d',
        fontFamily: 'RoobertENEL-Light, sans-serif'
      }
    },
    series: [{
      type: 'treemap',
      layoutAlgorithm: 'squarified',
      name: 'Performance',
      allowPointSelect: true,
      cursor: 'pointer',
      point: {
        events: {
          select: this.onPointSelect.bind(this),
        }
      },
      levels: [{
        level: 1,
        layoutAlgorithm: 'sliceAndDice',
        borderColor: 'black',
        borderWidth: 5,
        dataLabels: {
          enabled: true,
          align: 'center',
          verticalAlign: 'top',
          backgroundColor: 'rgba(252, 255, 197, 0.7)',
          borderColor: 'black',
          borderWidth: 1,
          style: {
            fontSize: '15px',
            fontWeight: 'bold',
            fontFamily: 'RoobertENEL-Light, sans-serif'
          }
        }
      },
        {
          level: 2,
          borderColor: 'white',
          borderWidth: 2,
        }],
    }],
    title: {
      text: null
    },
    credits: {
      enabled: false
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        if(this.point.type == 'EVENTNODE') {
          return ' ' +
            this.point.name + '<br />' +
            'Organization: ' + this.point.orgName + '<br />' +
            'Site Name: ' + this.point.siteName + '<br />' +
            'Current Performance: ' + this.point.currentPerfLabel + '<br />' +
            'Average Performance: ' + this.point.averagePerfLabel +   '<br />' +
            'Expected Capacity: ' + this.point.expectedCapacityLabel +  '<br />' +
            'Confirmation Status: ' + ConfirmationStatus[this.point.confirmationStatus ]+  '<br />'
        }
      },
      style: {
        fontFamily: "RoobertENEL-Light, sans-serif"
      }
    }
  }
  //endregion

  constructor(private EMS: EventManagementService, private SS: SharedService, private ELS: EventListService) {
    this.PNS = new PaginatedNodeService();

    const {fetchingNodes$, nodesToEventMap$, eventNodeUpdate$} = this.PNS;
    fetchingNodes$.pipe().subscribe((resp)=>{this.fetchingNodes = resp});
    nodesToEventMap$.pipe().subscribe((resp)=>{this.handleNewNodesList(resp)});
    eventNodeUpdate$.pipe().subscribe((resp)=>{this.handleEventNodeUpdate(resp)})

    this.refreshEvent = _.debounce(this.refreshEvent, 1000, {
      'leading': true,
      'trailing': false
    })
  }

  private ngUnsubscribe: Subject<any> = new Subject();

  ngOnInit(): void {
    this.productUOM = this.SS.getPrezUOM(this.product);
    this.paginationMeta.page = 1;
    this.paginationMeta.per_page = 200;
    this.paginationMeta.total_items = 0;
  }

  ngOnDestroy() {
    clearTimeout(this.getEventsTimeout)
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges){
    if(!!changes.events && changes.events.currentValue  && changes.events.currentValue != changes.events.previousValue){
      //console.log('ngOnChanges')
      // console.log(changes.events.currentValue)
      if(!this.selectedEvents.length && this.events.length) this.selectedEvents = this.events;
      if(this.selectedEvents?.length){
        this.updateSelectedEvents();
      }
    }
  }


  //region Component interactions
  onPaginate(){
    this.fetchNodes();
  }

  onFilter() {
    this.fetchNodes();
  }
  //endregion

  //region Data fetchin'
  fetchNodes(){
    let model = this.nodeListFilter.modelToSend;
    this.PNS.getNodes(model, this.paginator.currentPage, this.paginator.pageSize, true)
  }
  //endregion

  //region MQTT handlers
    handleNewNodesList(data){
    this.nodesToEventMap = this.PNS.nodesToEventMap;
    if(this.PNS.paginationMeta) this.paginationMeta = this.PNS.paginationMeta;
    this.setChartData();
  }

  handleEventNodeUpdate(data){
    this.nodesToEventMap = this.PNS.nodesToEventMap;
    let updatedNodeGroup: GroupedEventNodes = this.nodesToEventMap[data.event_id];
    let found = _.find(this.events, ['event_id', data.allNodes[0]?.event_id]);
    if(found){
      let nodeStatistics = found.event_node_statistics;
      if(nodeStatistics.active_event_nodes.length != updatedNodeGroup.activeNodes.length
        || nodeStatistics.pending_event_nodes.length != updatedNodeGroup.pendingNodes.length
        || nodeStatistics.opted_out_event_nodes.length != updatedNodeGroup.optedOutNodes.length){
        this.refreshEvent(found.event_id);
      }
    }
    this.setChartData()
  }

  refreshEvent(event_id){
    this.ELS.refreshEvent(event_id)
  }
  //region



  setChart(chart: Highcharts.Chart) {
    this.chart = chart;
  };

  updateSelectedEvents(){
    let deadMembers = []
    if(this.selectedEvents){
      this.selectedEvents.forEach((selected)=>{
        let found = _.find(this.events, ['event_id', selected.event_id]);
        if(!!found){
          selected = Object.assign(selected, found);
        } else {
          deadMembers.push(selected.event_id)
        }
      })
      if(deadMembers.length){
        this.selectedEvents = _.remove(this.selectedEvents, function(o) {
          return deadMembers.includes(o.event_id);
        });
      }
      this.setChartData();
    }
  }

  onPointSelect(target) {
    let url;

    if(target.target.type === 'EVENTNODE') {
      url = window.location.origin + '/eventnode/' + target.target.id;
    } else {
      url = window.location.origin + '/event/' + target.target.id;
    }
    window.open(url, '_blank');
  }

  getLatestEndTime():Moment {
    let arr=[];
    let latest;
    this.events.forEach((e: EPSEvent)=>{
      if(e.event_end_dttm_utc){
        arr.push(moment(e.event_end_dttm_utc))
      }
    })

    if(arr?.length){
      latest = moment.max(arr);
    }

    this.eventsOver = latest?.isSameOrBefore(moment());
    return arr.length ?  latest : null;
  }

  resetFilters() {
    this.performingChecked = false;
    this.performingAboveBelow = 0;
    this.performingAboveBelowPerCent = 20;
    this.expectedCapacityChecked = false;
    this.capacityAboveBelow = 0;
    this.capacityAboveBelowKw = 20;
    this.performingBy = 0;

    this.setChartData();
  }


  async setChartData() {
    if(!this.nodesToEventMap || !this.events) return;

    clearTimeout(this.chartRefreshTimeout);
    const controller = this;
    const event0 = controller.events[0]
    const chartData = [];

    this.noEventsSelected = false;

    if(controller.firstDataLoad) {

      controller.firstDataLoad = false

      if(this.getLatestEndTime() && this.getLatestEndTime().isSameOrBefore(moment())) {
        this.showBy = 2
      } else if(moment(event0.event_start_dttm_utc).isSameOrBefore(moment())) {
        this.showBy = 1
      } else {
        this.showBy = 0
      }
    }

    if(this.getLatestEndTime() && this.getLatestEndTime().isSameOrBefore(moment())) {
        if(controller.showBy === 1) {
          controller.showBy = 2;
        }
    }

    const determineColorValue = function(point){
      if(controller.product && (controller.product.performance_error_threshold > 0 || controller.product.performance_warning_threshold > 0)) {
        controller.errorThreshold = controller.product.performance_error_threshold;
        controller.warningThreshold = controller.product.performance_warning_threshold;
      }

      const gray = '#9e9e9e';
      const red = '#fc4b88';
      const green = '#59bc5f';
      const yellow = '#fecc32';
      const lightGreen = 'rgb(0, 207, 18, .5)';
      const uglyPurple = '#461e7d';

      let colorVal = gray;

      if(controller.showBy === 0) {
        if(point.confirmationStatus === ConfirmationStatus.Confirmed) {
          //confirmed
          colorVal = green;
        } else if(point.confirmationStatus === ConfirmationStatus.Preauthorized) {
          //preauth
          colorVal = lightGreen;
        } else {
          //unconfirmed
          colorVal = red;
        }
      } else if(moment(point.nodeStartTime).isSameOrBefore(moment())) {
        if(point.nodeEndTime &&
          point.eventEndTime &&
          moment(point.nodeEndTime).isBefore(moment(point.eventEndTime)) &&
          moment(point.eventEndTime).isSameOrAfter(moment().utc())) {
          colorVal = uglyPurple;
        } else {
          switch (controller.showBy) {
            //current performance
            case 1:
              if(point.currentPerformance != null) {
                if(point.currentPerformance <= controller.errorThreshold) {
                  colorVal = red;
                } else if(point.currentPerformance > controller.errorThreshold && point.currentPerformance <= controller.warningThreshold) {
                  colorVal = yellow;
                } else if(point.currentPerformance > controller.warningThreshold) {
                  colorVal = green;
                }
              }
              break;
            //average performance
            case 2:
              if(point.averagePerformance != null) {
                if(point.averagePerformance <= controller.errorThreshold) {
                  colorVal = red;
                } else if(point.averagePerformance > controller.errorThreshold && point.averagePerformance <= controller.warningThreshold) {
                  colorVal = yellow;
                } else if(point.averagePerformance > controller.warningThreshold) {
                  colorVal = green;
                }
              }
              break;
          }
        }
      }
      point['color'] = colorVal;
    }

    const getConfirmationStatus = function(node: EventNode) {
      if(node.confirmed && node.confirmed.length) {
        return ConfirmationStatus.Confirmed
      } else if(node.preauthorized) {
        return ConfirmationStatus.Preauthorized
      } else {
        return ConfirmationStatus.Unconfirmed
      }
    }

    let allActiveReggies = [];
    let allPassiveReggies = [];
    let activeRegistrations = [];
    let passiveRegistrations = [];
    let totalCapacityValue = 0;


    controller.selectedEvents.forEach((e:EPSEvent)=>{

      if(this.showBy !== 1 || this.showBy === 1 && !e.event_end_dttm_utc || (this.showBy === 1 && moment(e.event_end_dttm_utc).isAfter(moment().utc())) && controller.nodesToEventMap && controller.nodesToEventMap[e.event_id]) {
        chartData.push ({
          id: e.event_id,
          name: e.portfolio_display_label,
          product: controller.product,
          type: 'EVENT'
        });

        activeRegistrations = [...controller.nodesToEventMap[e.event_id].completedNodes, ...controller.nodesToEventMap[e.event_id].activeNodes];

        allActiveReggies = [...allActiveReggies, ...activeRegistrations]


        activeRegistrations.forEach((r: EventNode)=>{
          let nodeDataPoint = {
            id: r.event_node_id,
            parent: e.event_id,
            value: r.expected_capacity_value,
            name: r.registration_display_label,
            orgName: r.organization_display_label,
            siteName: r.site_name,
            currentPerformance:  r.last_current_performance_percentage,
            currentPerfLabel: moment(r.event_node_end_dttm_utc).isSameOrBefore(moment().utc()) ? ' -' : this.SS.convertProductUOM(r.last_current_performance_value, controller.product, true),
            averagePerformance: r.average_performance_percentage,
            averagePerfLabel: this.SS.convertProductUOM(r.average_performance_value, controller.product, true),
            expectedCapacityLabel: this.SS.convertProductUOM(r.expected_capacity_value, controller.product, true),
            confirmationStatus: getConfirmationStatus(r),
            nodeStartTime: r.event_node_start_dttm_utc,
            nodeEndTime: r.event_node_end_dttm_utc,
            eventEndTime: e.event_end_dttm_utc,
            eventWorkflowStatus: e.workflow_status,
            type: 'EVENTNODE'
          }

          determineColorValue(nodeDataPoint);
          chartData.push(nodeDataPoint)
        })
      }
    })

    if(!chartData.length) {
      controller.noEventsSelected = true;
      controller.noEventsMessage = this.showBy === 1? 'No Active Event(s) selected, please select at least one.' : 'No Event(s) selected, please select at least one.';

    } else if(!_.find(chartData, { 'type': 'EVENTNODE' })) {
      controller.noEventsSelected = true;
      controller.noEventsMessage = 'No event nodes to display.'
    }


    const capacityRange = _.map(_.orderBy(allActiveReggies, ['expected_capacity_value']), 'expected_capacity_value');
    this.maxCapacity = +(this.SS.convertProductUOM( _.last(capacityRange), this.product, false));
    this.minCapacity = +(this.SS.convertProductUOM( _.first(capacityRange), this.product, false));


    setTimeout(()=>{
      this.updateChartData(chartData)
    })
  }

  updateChartData(data) {
    if(this.chart && this.chart.series) {
      this.chart.series[0].setData(data.sort(), false);
      this.chart.redraw();
      this.chartRefreshTimeout = setTimeout(()=>{
        this.setChartData()
      }, 30000)
    }
  }

  onSetResolution(){
    //console.log(this.chartResolutions)
    let res = this.chartResolutions[this.selectedChartSize];
    this.chart.setSize(res.width, res.height)
  }

  getNodeCount(event_id, status){
    if(!event_id || !this.nodesToEventMap) return 0;
    let grp: GroupedEventNodes = this.nodesToEventMap[event_id];

      switch(status){
        case 'active':
          return grp?.activeNodes?.length || 0;
        case 'pending':
          return grp?.pendingNodes?.length || 0;
        case 'optedout':
          return grp?.optedOutNodes?.length || 0;
      }


  }
}
