import { Component, Input, OnInit } from '@angular/core';
import { EventListService } from '../../services/event-list.service';
import * as moment from "moment";
import { EPSEvent } from '../../classes/event';
import { catchError, takeUntil } from 'rxjs/operators';
import { forkJoin, from } from 'rxjs';
import {Subject} from "rxjs/internal/Subject";
import * as _ from 'lodash';
import { EventManagementService } from '../../services/event-management.service';

@Component({
  selector: '[app-notification-results]',
  templateUrl: './notification-results.component.pug',
  styleUrls: ['./notification-results.component.scss']
})
export class NotificationResultsComponent implements OnInit {

  @Input() eventNode;
  @Input() event:EPSEvent;

  finishedGettingNotifs:boolean = false;
  notifResults = [];
  contactPathMap = {SMS: 'SMS', EMAIL: 'Email', VOICE: 'Voice'};
  getNotificationsSummary$;
  getTemplates$;
  templatesMap = {};



  constructor(private EMS: EventManagementService) { }
  private ngUnsubscribe: Subject<any> = new Subject();

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.getNotificationsSummary$ = this.EMS.get('/v1/notification_summary/' + this.eventNode.event_node_id, {}).pipe(
      takeUntil(this.ngUnsubscribe),
      catchError((err)=> {
        console.dir("Error getting notification summary for event node with ID: " + this.eventNode.event_node_id);
        return from(new Promise((resolve) => resolve(true))) ;
      }));

    this.getTemplates$ = this.EMS.get('/v1/templates?filter=DEMAND_RESPONSE', null).pipe(
      takeUntil(this.ngUnsubscribe),
      catchError((err)=> {
        console.dir("Error getting templates");
        return from(new Promise((resolve) => resolve(true))) ;
      }));

    forkJoin([this.getNotificationsSummary$, this.getTemplates$ ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      ([res1, res2]) => {
        this.parseSummary(res1) ;
        if(res2) {
          this.templatesMap = _.keyBy(res2['data'], 'template_name');
        }
        this.finishedGettingNotifs = true;
      }
    );
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  parseSummary(result) {
    if(result && result.data){
      //this is going to get ugly
      const templates = _.groupBy(result.data, 'template_name');
      let arr = [];
      Object.keys(templates).map((templateName) => {
        let template = templates[templateName];
        template.recipients = _.groupBy(template, 'user_id');
        let recipientsArr = [];
        let totalRows = 0;
        Object.keys(template.recipients).map((recipient) => {
            let boo = {firstName: template.recipients[recipient][0].contact_first_name, lastName: template.recipients[recipient][0].contact_last_name, contactPaths:  _.orderBy(_.values(_.keyBy(template.recipients[recipient], 'contact_path')), 'contact_path')}
            recipientsArr.push(boo);
            totalRows += boo.contactPaths.length;
          }
        );
        arr.push({'template': templateName, recipients: recipientsArr, rows:totalRows})
      });
      this.notifResults = arr;
    }
    this.finishedGettingNotifs = true;
  }

  getTimeString(time){
    return !!time ? moment(time).toISOString() : null;
  }

  getTemplateLabel(templateName){
    if(this.templatesMap[templateName]) {
      return this.templatesMap[templateName].display_label;
    }
    return(templateName)
  }
  getContactPathName(contactPath) {
    if(this.contactPathMap[contactPath]) {
      return this.contactPathMap[contactPath]
    }
    return contactPath;
  }
}
