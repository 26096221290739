import { Injectable } from '@angular/core';
import { forkJoin, from, ReplaySubject } from 'rxjs';
import { AppInjector } from '../app.module';
import { GroupedEventNodes } from '../classes/event_node';
import { PaginationMeta } from '../classes/paginationMeta';
import { NodeFilterModel } from '../components/node-list-filter/node-list-filter.component';
import { EventListService } from './event-list.service';
import { EventManagementService } from './event-management.service';
import { MqttSubscriptionService } from './mqtt-subscription.service';
import { NodesFormatService } from './nodes-format.service';
import { RefDataService } from './ref-data.service';
import { SharedService } from './shared.service';
import * as _ from 'lodash';
import { map, takeUntil } from 'rxjs/operators';


@Injectable()
export class PaginatedNodeService {
  NFS: NodesFormatService;
  EMS: EventManagementService;
  SS: SharedService;
  ELS: EventListService;
  nodesSubscription;
  nodesToWatch;
  eventsSubscription;
  nodesToEventMap;

  fetchingNodes$ = new ReplaySubject<boolean>();
  groupedNodes$ = new ReplaySubject<any>();
  eventNodeUpdate$ = new ReplaySubject<any>();
  nodesToEventMap$ = new ReplaySubject<any>();

  public paginationMeta;
  public groupedNodes: GroupedEventNodes;

  constructor() {
    this.EMS = AppInjector.get(EventManagementService);
    this.SS = AppInjector.get(SharedService);
    this.NFS = new NodesFormatService();

  }

  getNodes(model: NodeFilterModel, currentPage, pageSize, mappedToEventIds = false,  separateOptedOut = false){

    this.fetchingNodes$.next(true);
    if(separateOptedOut && model.event_node_status?.includes('opted_out')) {
      _.pull(model.event_node_status, 'opted_out')
    }
    let body = {event_ids: model.eventIds};

    let params = {}
    const keys = Object.keys(model);
    keys.forEach((key)=>{
      if(typeof model[key] === 'number' || !!model[key]){
        params[key] = model[key]
        if(Array.isArray(params[key])){
          params[key] = params[key].join(',')
        }
      }
    })
    delete params['eventIds'];
    params = {...params, ...{page: currentPage, per_page: pageSize}}
  
    let getOptedOutNodes$ = from(new Promise((resolve) => resolve({data: null})));
    let getAllOtherNodes$ = this.EMS.post('/v1/events/nodes_for_events', body, params).pipe(map((resp)=>{return resp}));

    if(separateOptedOut) {
      let ooParams = JSON.parse(JSON.stringify(params))
      ooParams['event_node_status']='opted_out'
      getOptedOutNodes$ = this.EMS.post('/v1/events/nodes_for_events', body, ooParams).pipe(map((resp)=>{return resp}));
    }

    forkJoin(getAllOtherNodes$, getOptedOutNodes$).pipe().subscribe(
      ([otherNodes, optedOutNodes])=>{

        let nodesData = _.merge(otherNodes['data'], optedOutNodes['data']);
        this.NFS.initGroupedNodes(nodesData).then(
          (grp)=>{
            this.groupedNodes = grp;

            if(otherNodes['meta']) {
              this.paginationMeta = otherNodes['meta'];
            } else {
              this.paginationMeta = new PaginationMeta();
              this.paginationMeta.total_items = 0;
            }

            this.groupedNodes$.next(this.groupedNodes);
            this.nodesToWatch = _.map(this.groupedNodes.allNodes, 'event_node_id');

            if(mappedToEventIds){
              let obj = {};
              const nodesMapped = _.after(model.eventIds.length, ()=>{
                this.nodesToEventMap = obj;
                this.nodesToEventMap$.next(this.nodesToEventMap);
              })
              model.eventIds.forEach((evId)=>{
                let gn = new GroupedEventNodes();
                gn.activeNodes    = _.filter(this.groupedNodes.activeNodes, {'event_id': evId});
                gn.pendingNodes   = _.filter(this.groupedNodes.pendingNodes, {'event_id': evId});
                gn.completedNodes = _.filter(this.groupedNodes.completedNodes, {'event_id': evId});
                gn.cancelledNodes = _.filter(this.groupedNodes.cancelledNodes, {'event_id': evId});
                gn.excludedNodes  = _.filter(this.groupedNodes.excludedNodes, {'event_id': evId});
                gn.optedOutNodes  = _.filter(this.groupedNodes.optedOutNodes, {'event_id': evId});
                gn.allNodes       = _.filter(this.groupedNodes.allNodes, {'event_id': evId});

                obj[evId] = gn;
                nodesMapped();
              })
              this.fetchingNodes$.next(false);
            } else {
              this.fetchingNodes$.next(false);
            }

            //subscribe
            this.nodesSubscription?.clearSubscription();
            this.nodesSubscription = new MqttSubscriptionService();
            this.nodesSubscription.subscribeToTopic(this.nodesToWatch, 'EVENT_NODE', (msg)=>{
               //console.log('EVENT_NODE: ' + JSON.stringify(msg))
              this.updateNode(msg, mappedToEventIds)
            })
            this.eventsSubscription?.clearSubscription();
            this.eventsSubscription = new MqttSubscriptionService();
            this.eventsSubscription.subscribeToTopic(model.eventIds, 'event', (msg)=>{
               //console.log('event: ' + JSON.stringify(msg))
              this.updateNode(msg, mappedToEventIds)
            }) }
        );
      },
      (err)=>{
        //this.groupedNodes$.next(new GroupedEventNodes());
        this.fetchingNodes$.next(false);
      }
    )
  }

  updateNode(msg, mappedToEventIds){
    if(mappedToEventIds){
      if(this.nodesToEventMap && this.nodesToEventMap[msg.event_id]){
        this.NFS.updateNode(msg, this.nodesToEventMap[msg.event_id]).then(
          (resp)=>{
            this.nodesToEventMap[resp.event_id] = resp;
            this.eventNodeUpdate$.next(resp);
          }
        )
      }
    } else {
      if(this.nodesToWatch.includes(msg.event_node_id)){
        this.NFS.updateNode(msg, this.groupedNodes).then(
          (resp)=>{
            this.groupedNodes = resp;
            this.eventNodeUpdate$.next(resp);
          }
        )
      }
    }
  }
}
