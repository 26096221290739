import { Component, Input, OnInit } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment-timezone/builds/moment-timezone-with-data-2012-2022.min";
import { EventNode, EventNodeDateOverlap } from "src/app/classes/event_node";
import {
  ModalDatePickerData,
  OwlTimeData,
} from "../../../../classes/modalDatePickerData";
import { SharedService } from "../../../../services/shared.service";

@Component({
  selector: "app-notify-modal",
  templateUrl: "./notify-modal.component.pug",
  styleUrls: ["./notify-modal.component.scss"],
})
export class NotifyModalComponent implements OnInit {
  @Input() data: any;

  deliveryPathSMS = false;
  deliveryPathEMAIL = false;
  deliveryPathVOICE = false;
  deliveryPaths: string[] = [];
  selectedTemplate = null;
  automated: boolean = false;
  event_node_ids: string[] = [];
  showSMSPath: boolean;
  showEMAILPath: boolean;
  showVOICEPath: boolean;
  isAggregate = false;
  selectedNodes = [];

  constructor(private shared: SharedService) {}

  ngOnInit(): void {
    this.isAggregate = !!this.data?.isAggregate;
  }

  close() {
    this.data.close();
  }

  confirm() {
    let hasErrors: boolean = false;
    let tpd = this.data.timePickerData;
    const date = moment.tz(moment(tpd.entered_time).format('YYYY-MM-DDTHH:mm'), this.data.program_time_zone);
    tpd.errors = [];

    if (date.isValid()) {
      let chosenDateUTC = date.utc();

      if (chosenDateUTC.isBefore(moment.utc(tpd.min_date))) {
        hasErrors = true;
        tpd.errors.push(
          "Entered date must be the same as or after " +
            moment
              .tz(
                tpd.min_date,
                this.shared.getTimeZoneName(this.data.program_time_zone, false)
              )
              .format(SharedService.dateFormat)
        );
      }

      if (chosenDateUTC.isAfter(moment.utc(tpd.max_date))) {
        hasErrors = true;
        tpd.errors.push(
          "Entered date must be the same as or before " +
            moment
              .tz(
                tpd.max_date,
                this.shared.getTimeZoneName(this.data.program_time_zone, false)
              )
              .format(SharedService.dateFormat)
        );
      }
    } else {
      hasErrors = true;
      tpd.errors.push("Invalid date entered.");
    }

    if (!hasErrors) {
      const notificationBody = {
        action_type: "NOTIFICATION",
        action_parameter: this.selectedTemplate.template_name,
        filter_type: "MANUAL",
        scheduled_time: this.shared
          .selectedTimeZoneToUTC(tpd.entered_time, this.data.program_time_zone)
          .toISOString(),
        automated: this.automated,
        delivery_methods: this.deliveryPaths,
        event_node_ids: _.map(this.data.selectedNodes, "event_node_id"),
      };
      this.data.confirmFunction(notificationBody);
      this.data.close();
    }
  }

  getNodeOverlap(node: EventNode) {
    const convertedDate = moment.tz(
      node.overlap.getEnd(),
      this.data.program_time_zone
    );
    return convertedDate.format(SharedService.dateFormat);
  }

  getTimezone() {
    return this.shared.getTimeZoneName(this.data.program_time_zone);
  }

  getTimeZoneConversion(timePickerData: ModalDatePickerData, zone: string) {
    let localTZ = this.shared.getUsersTimeZone();
    let tz: string;

    switch (zone) {
      case "local":
        tz = localTZ;
        break;
      case "program":
        tz = this.data.program_time_zone;
        break;
      case "utc":
        tz = "UTC";
        break;
    }

    let convertedTime: string = this.shared
      .selectedTimeZoneToUTC(
        timePickerData.entered_time,
        this.data.program_time_zone
      )
      .toISOString();
    return timePickerData.entered_time
      ? moment.tz(convertedTime, tz).format(SharedService.dateFormat)
      : "None";
  }

  onChangeDate(time: OwlTimeData, tpd: ModalDatePickerData) {
    const enteredTime = time.date;
    tpd.entered_time = enteredTime;
    const timezone = this.data.program_time_zone;
    const convertedTime = this.shared.selectedTimeZoneToUTC(
      tpd.entered_time,
      timezone
    );

    if (this.isAggregate) {
      let hasOverlaps = false;
      this.selectedNodes = this.data.selectedNodes.map((node: EventNode) => {
        const nodeEndTime = node.event_node_end_dttm_utc;
        const endOverlap = convertedTime.isAfter(nodeEndTime)
          ? node.event_node_end_dttm_utc
          : null;
        node.overlap = new EventNodeDateOverlap(null, endOverlap);
        hasOverlaps = hasOverlaps || node.overlap.hasEndOverlap();
        return node;
      });
    }
  }

  onCheckDeliveryPath(path: string, checked: boolean): void {
    if (checked) {
      this.deliveryPaths.push(path);
    } else {
      _.pull(this.deliveryPaths, path);
    }
  }
}
