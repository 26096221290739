import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Tree from 'highcharts/modules/treemap';
import { EPSEvent } from '../../../classes/event';
import { Product } from '../../../classes/product';
import { SharedService } from '../../../services/shared.service';





More(Highcharts);
Tree(Highcharts);


@Component({
  selector: 'app-obligations-map',
  templateUrl: './obligations-map.component.pug',
  styleUrls: ['./obligations-map.component.scss']
})
export class ObligationsMapComponent implements OnInit, OnChanges {
  @Input() events: EPSEvent[];
  @Input() product: Product;
  @Input() selectingEvents: boolean;

  Highcharts = Highcharts;
  chart = null;
  updateChart:boolean = false;
  chartOptions;

  constructor(private SS:SharedService) { }

  ngOnInit(): void {
    this.chartOptions = this.getChartOptions();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.events?.currentValue !== changes.events?.previousValue){
      this.setChartData()
    }
  }

  getChartOptions(){
    const controller = this;
    return {
      chart: {
        borderWidth: 1,
        borderColor: '#d8d8d8',
        spacingBottom: 15,
        spacingTop: 10,
        spacingLeft: 10,
        spacingRight: 10,
        style: {
          color: '#461e7d',
          fontFamily: 'RoobertENEL-Light, sans-serif'
        }
      },
      series: [{
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        name: 'Performance',
        allowPointSelect: true,
        point: {
          events: {
            select: this.onPointSelect.bind(this),
          }
        },
      }, {
        type: 'area',
        color: '#d2aaff',
        name: 'Event Status BEFORE'
      }, {
        type: 'area',
        color: '#461e7d',
        name: 'Event Status AFTER'
      }, {
        type: 'area',
        color: '#00CF12',
        name: 'Performing'
      }, {
        type: 'area',
        color: '#FFE701',
        name: 'Close to underperforming'
      }, {
        type: 'area',
        color: '#F33C4D',
        name: 'Underperforming'
      }, {
        type: 'area',
        color: '#9e9e9e',
        name: 'No performance data'
      }],
      tooltip:
        {
          useHTML: true,
          formatter: function() {
            return ' ' +
              'Event Name: ' + this.point.name + '<br />' +
              'Event Progress Status: ' + this.point.status + '<br />' +
              'Obligation: ' + (this.point.product ? controller.SS.convertProductUOM(this.point.obligation, this.point.product, true, 3, false, true) : 'Error getting Product') + '<br />' +
              'Performance: ' + (this.point.performance ? this.point.performance.toFixed(3) : 0) + '%';
          },
          style: {
            fontFamily: "RoobertENEL-Light, sans-serif"
          }
        },
      title: {
        text: 'Event Obligations',
        y: 35,
        floating: true,
        align: 'left',
        style: {
          color: '#461e7d',
          fontFamily: 'RoobertENEL-Light, sans-serif'
        }
      },
      credits: {
        enabled: false,
      },
      legend:{
        align: 'right',
        verticalAlign: 'top',
        title: {
          text: "Active Event Performance (%):",
          style: {
            color: '#717171',
            fontFamily: "RoobertENEL-Light, sans-serif"
          }
        }
      }
    };
  }


  setChart(chart: Highcharts.Chart) {
    this.chart = chart;
    this.setChartData();
  };



  setChartData(){
    let data = [];

    const controller = this;

    const determineColorValue = function(point){
      const errorThreshold = controller.product.performance_error_threshold || 100;
      const warningThreshold = controller.product.performance_warning_threshold || 105;

      switch(point.event['event_progress_status']) {

        case 'BEFORE':
          point['color'] = '#d2aaff';
          break;
        case 'AFTER':
          point['color'] = '#461e7d';
          break;
        case 'DURING':
          if(!point.performance){
            point['color'] = '#9e9e9e';
          }
          else if(point.performance <= errorThreshold)
            //red
            point['color'] = '#F33C4D';
          else if(point.performance > errorThreshold && point.performance < warningThreshold)
            //yellow
            point['color'] = '#FFE701';
          else if( point.performance >= warningThreshold)
            //green
            point['color'] = '#00CF12';
          return;
        default:
          point['color'] = '#9e9e9e';
          break;
      }
    };

    if(controller.events?.length) {
      controller.events.forEach(ev => {
        let thisDataPoint = {
          event: ev,
          name: ev.event_name,
          value: ev.obligation,
          colorValue: ev['event_performance_percent'],
          obligation: ev.obligation,
          performance: ev['event_performance_percent'],
          event_id: ev.event_id,
          status: ev['event_progress_status'],
          product: controller.product || null
        };

        determineColorValue(thisDataPoint);
        data.push(thisDataPoint);
      });

      this.updateChartData(data);
    }
  }


  updateChartData(data) {

    if(this.chart && this.chart.series) {
      this.chart.series[0].setData(data, false);
      this.chart.redraw();
    }
  }

  onPointSelect(target) {
    this.goToEventDetail(target.target.options.event);
  }

  goToEventDetail(event) {
    if(!this.selectingEvents) {
      const url = window.location.origin + '/event/' + event.event_id;
      window.open(url, '_blank');
    }
  }
}
