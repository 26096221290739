import * as _ from 'lodash';

enum EventNodeStatus {
  none = 'none',
  active = 'active',
  pending = 'pending',
  optedOut = 'optedOut',
  completed = 'completed',
  excluded = 'excluded',
  cancelled = 'cancelled',
}

export { EventNodeStatus };

export class GroupedEventNodes {
  pendingNodes: Array<EventNode> = [];
  optedOutNodes: Array<EventNode> = [];
  activeNodes: Array<EventNode> = [];
  completedNodes: Array<EventNode> = [];
  excludedNodes: Array<EventNode> = [];
  cancelledNodes: Array<EventNode> = [];
  allNodes: Array<EventNode> = [];
}

//export {GroupedEventNodes}

//DEPRECATED
class EventNodeWrapper {
  pendingNodes: Array<EventNode>;
  optedOutNodes: Array<EventNode>;
  activeNodes: Array<EventNode>;
  completedNodes: Array<EventNode>;
  excludedNodes: Array<EventNode>;
  cancelledNodes: Array<EventNode>;
  allNodes: Array<EventNode>;
  eventNodeStatuses;

  public setEventNodes(eventNodes, workflowStatusRefData, templateRefData, registrationTypeRefData, controlTypeReferenceData?) {
    this.eventNodeStatuses = EventNodeStatus;

    this.pendingNodes = eventNodes.pendingNodes;
    this.optedOutNodes = eventNodes.optedOutNodes;
    this.activeNodes = eventNodes.activeNodes;
    this.completedNodes = eventNodes.completedNodes;
    this.excludedNodes = eventNodes.excludedNodes;
    this.cancelledNodes = eventNodes.cancelledNodes;

    this.setLabels(
      this.optedOutNodes,
      workflowStatusRefData,
      templateRefData,
      registrationTypeRefData,
      controlTypeReferenceData,
      this.eventNodeStatuses.optedOut,
    );
    this.setLabels(
      this.activeNodes,
      workflowStatusRefData,
      templateRefData,
      registrationTypeRefData,
      controlTypeReferenceData,
      this.eventNodeStatuses.active,
    );
    this.setLabels(
      this.completedNodes,
      workflowStatusRefData,
      templateRefData,
      registrationTypeRefData,
      controlTypeReferenceData,
      this.eventNodeStatuses.completed,
    );
    this.setLabels(
      this.pendingNodes,
      workflowStatusRefData,
      templateRefData,
      registrationTypeRefData,
      controlTypeReferenceData,
      this.eventNodeStatuses.pending,
    );
    this.setLabels(
      this.excludedNodes,
      workflowStatusRefData,
      templateRefData,
      registrationTypeRefData,
      controlTypeReferenceData,
      this.eventNodeStatuses.excluded,
    );
    this.setLabels(
      this.cancelledNodes,
      workflowStatusRefData,
      templateRefData,
      registrationTypeRefData,
      controlTypeReferenceData,
      this.eventNodeStatuses.cancelled,
    );
  }

  public clear() {
    this.pendingNodes = [];
    this.activeNodes = [];
    this.optedOutNodes = [];
    this.completedNodes = [];
    this.excludedNodes = [];
    this.cancelledNodes = [];
  }

  public setLabels(nodes, workflowStatusRefData, templateRefData, registrationTypeRefData, controlTypeReferenceData, status) {
    _.forEach(nodes, node => {
      node.confStatus = node.confirmed || node.preauthorized ? 'Confirmed' : 'Unconfirmed';
      node.eventNodeStatusForSort = status;

      _.forEach(workflowStatusRefData, wfs => {
        if (wfs.code === node.workflow_status) {
          node['workflow_status_display_label'] = wfs['display_label'];
        }
        if (wfs.code === node.last_notification_sent) {
          node['last_notification_sent_display_label'] = wfs['display_label'];
        }
      });

      _.forEach(templateRefData, template => {
        if (template.template_name === node.last_notification_sent) {
          node['last_notification_sent_display_label'] = template['display_label'];
        }
      });

      _.forEach(registrationTypeRefData, type => {
        if (type.code === node.registration_type) {
          node['registration_type_display_label'] = type['display_label'];
        }
      });

      _.forEach(controlTypeReferenceData, ctype => {
        if (ctype.code === node.control_type) {
          node['control_type_display_label'] = ctype['display_label'];
        }
      });
    });
  }

  public setLabel(node, workflowStatusRefData, templateRefData, registrationTypeRefData, controlTypeReferenceData, next) {
    if (!!node.cancelled || !!node.cancelled_no_restore || !!node.cancelled_with_restore) {
      node.workflow_status = 'WORKFLOW_COMPLETE';
    }
    switch (node.workflow_status) {
      case 'PENDING':
        if (node.opted_out) node.eventNodeStatusForSort = 'optedOut';
        else node.eventNodeStatusForSort = 'pending';
        break;
      case 'WORKFLOW_COMPLETE':
        if (node.cancelled) node.eventNodeStatusForSort = 'cancelled';
        else if (node.activation_time !== null) node.eventNodeStatusForSort = 'completed';
        else node.eventNodeStatusForSort = 'excluded';
        break;
      default:
        node.eventNodeStatusForSort = 'active';
        break;
    }

    node.confStatus = node.confirmed || node.preauthorized ? 'Confirmed' : 'Unconfirmed';

    _.forEach(workflowStatusRefData, wfs => {
      if (wfs.code === node.workflow_status) {
        node['workflow_status_display_label'] = wfs['display_label'];
      }
      if (wfs.code === node.last_notification_sent) {
        node['last_notification_sent_display_label'] = wfs['display_label'];
      }
      next();
    });

    _.forEach(templateRefData, template => {
      if (template.template_name === node.last_notification_sent) {
        node['last_notification_sent_display_label'] = template['display_label'];
      }
      next();
    });

    _.forEach(registrationTypeRefData, type => {
      if (type.code === node.registration_type) {
        node['registration_type_display_label'] = type['display_label'];
      }
      next();
    });

    _.forEach(controlTypeReferenceData, ctype => {
      if (ctype.code === node.control_type) {
        node['control_type_display_label'] = ctype['display_label'];
      }
      next();
    });
  }

  //Take in an event node workflow or event node and update our saved version of it
  public updateEventNode(data, workflowStatusRefData, templateRefData, registrationTypeRefData, controlTypeReferenceData) {
    let done = false;

    //CHECK ACTIVE NODES
    for (let i = 0; i < this.activeNodes.length; i++) {
      //Stop early if we found our node
      if (done) break;

      //If it's a match, set the data, display labels, and move it to a new list if necessary
      if (this.activeNodes[i].event_node_id === data.event_node_id) {
        this.activeNodes[i] = Object.assign(this.activeNodes[i], data);

        const doneWithLabels = _.after(workflowStatusRefData.length + templateRefData.length + registrationTypeRefData.length, () => {
          //If the new version is pending or opted out, move it to the correct list
          if (this.activeNodes[i].workflow_status === 'PENDING') {
            this.pendingNodes.push(this.activeNodes[i]);
            this.activeNodes.splice(i, 1);
          } else if (this.activeNodes[i].opted_out !== null) {
            this.optedOutNodes.push(this.activeNodes[i]);
            this.activeNodes.splice(i, 1);
          } else if (this.activeNodes[i].cancelled) {
            this.cancelledNodes.push(this.activeNodes[i]);
            this.activeNodes.splice(i, 1);
          }
          done = true;
        });

        this.setLabel(
          this.activeNodes[i],
          workflowStatusRefData,
          templateRefData,
          registrationTypeRefData,
          controlTypeReferenceData,
          doneWithLabels,
        );
      }
    }

    //CHECK PENDING NODES
    if (!done) {
      for (let i = 0; i < this.pendingNodes.length; i++) {
        //Stop early if we found our node
        if (done) break;

        //If it's a match, set the data, display labels, and move it to a new list if necessary
        if (this.pendingNodes[i].event_node_id === data.event_node_id) {
          this.pendingNodes[i] = Object.assign(this.pendingNodes[i], data);

          const doneWithLabels = _.after(workflowStatusRefData.length + templateRefData.length + registrationTypeRefData.length, () => {
            //If the new version is active or opted out, move it to the correct list
            if (this.pendingNodes[i]) {
              if (this.pendingNodes[i].workflow_status === 'WORKFLOW_COMPLETE') {
                this.excludedNodes.push(this.pendingNodes[i]);
                this.pendingNodes.splice(i, 1);
              } else if (this.pendingNodes[i].workflow_status !== 'PENDING') {
                this.activeNodes.push(this.pendingNodes[i]);
                this.pendingNodes.splice(i, 1);
              } else if (this.pendingNodes[i].opted_out !== null) {
                this.optedOutNodes.push(this.pendingNodes[i]);
                this.pendingNodes.splice(i, 1);
              } else if (this.pendingNodes[i].cancelled) {
                this.cancelledNodes.push(this.pendingNodes[i]);
                this.pendingNodes.splice(i, 1);
              }
            }
            done = true;
          });

          this.setLabel(
            this.pendingNodes[i],
            workflowStatusRefData,
            templateRefData,
            registrationTypeRefData,
            controlTypeReferenceData,
            doneWithLabels,
          );
        }
      }
    }

    if (!done) {
      //CHECK OPTED OUT NODES
      for (let i = 0; i < this.optedOutNodes.length; i++) {
        //Stop early if we found our node
        if (done) break;

        //If it's a match, set the data, display labels, and move it to a new list if necessary
        if (this.optedOutNodes[i].event_node_id === data.event_node_id) {
          this.optedOutNodes[i] = Object.assign(this.optedOutNodes[i], data);

          const doneWithLabels = _.after(workflowStatusRefData.length + templateRefData.length + registrationTypeRefData.length, () => {
            //If the new version is active or opted out, move it to the correct list
            if (this.optedOutNodes[i] && this.optedOutNodes[i].opted_out === null) {
              if (this.optedOutNodes[i].workflow_status === 'PENDING') {
                this.pendingNodes.push(this.optedOutNodes[i]);
                this.optedOutNodes.splice(i, 1);
              } else {
                this.activeNodes.push(this.optedOutNodes[i]);
                this.optedOutNodes.splice(i, 1);
              }
            }
            done = true;
          });

          this.setLabel(
            this.optedOutNodes[i],
            workflowStatusRefData,
            templateRefData,
            registrationTypeRefData,
            controlTypeReferenceData,
            doneWithLabels,
          );
        }
      }
    }
  }
}

class EventNode {
  activation_time: string;
  alt_id: string;
  average_performance_percentage: number;
  average_performance_status: string;
  average_performance_uom: string;
  average_performance_value: number;
  bonus_uom: string;
  bonus_value: number;
  cancelled: string;
  cancelled_no_restore: string;
  cancelled_with_restore: string;
  confirmed: string;
  preauthorized: string;
  created_dttm: string;
  curtail_complete: string;
  curtail_exception_detected: string;
  curtail_exception_resolved: string;
  curtail_included: boolean;
  curtail_time: string;
  registration_display_labels;
  registration_display_label: string;
  event_id: string;
  event_node_end_dttm_utc: string;
  event_node_end_time: string;
  event_node_id: string;
  event_node_start_dttm_utc: string;
  event_node_start_time: string;
  expected_capacity_uom: string;
  expected_capacity_value: number;
  firm_service_level_uom: string;
  firm_service_level_value: number;
  follow_up_notif_included: boolean;
  follow_up_notif_time: string;
  initial_notif_included: boolean;
  initial_notif_time: string;
  is_fsl_indicator: boolean;
  is_generator_indicator: boolean;
  last_current_metered_uom: string;
  last_current_metered_value: number;
  last_current_performance_dttm_program_locale: string;
  last_current_performance_dttm_utc: string;
  last_current_performance_percentage: number;
  last_current_performance_status: string;
  last_current_performance_uom: string;
  last_current_performance_value: number;
  last_notification_sent: string;
  last_notification_sent_dttm: string;
  last_updated_dttm: string;
  manual_trip_time: string;
  max_end_program_offset_min: string;
  opted_out: string;
  participation_plan_id: string;
  portfolio_id: string;
  product_id: string;
  registered_capacity_uom: string;
  registered_capacity_value: number;
  registration_id: string;
  remove_from_workflow: string;
  restore_complete: string;
  restore_exception_detected: string;
  restore_exception_resolved: string;
  restore_included: boolean;
  restore_notif_included: boolean;
  restore_notif_time: string;
  restore_time: string;
  restore_trip_notif_included: boolean;
  restore_trip_notif_time: string;
  restore_trip_time: string;
  site_display_label;
  site_id: string;
  trip_complete: string;
  trip_exception_detected: string;
  trip_exception_resolved: string;
  trip_restore_offset: string;
  tripped_notif_included: boolean;
  tripped_notif_time: string;
  ufr_workflow: string;
  workflow_completed: string;
  workflow_status: string;
  device_link: string;
  realtime_availability: number;
  workflow_status_display_label: string;
  last_notification_sent_display_label: string;
  control_type: string;
  site_name: string;
  assignee: string;
  organization_display_label: string;
  opted_out_reason: string;
  opted_out_user_id: string;
  registration_type: string;
  registration_type_display_label: string;
  adjustment_window_start: string;
  adjustment_window_end: string;
  notes;
  expected_capacity: number;
  estimate_performance_ind: boolean;
  control_type_display_label: string;
  minimum_performance_value: number;
  minimum_performance_percentage: number;
  minimum_performance_uom: string;
  maximum_performance_value: number;
  maximum_performance_percentage: number;
  maximum_performance_uom: string;
  performance_start_dttm_utc: string;
  performance_end_dttm_utc: string;
  note_details: NoteDetails;

  //This stuff is for the event detail page
  shortfall: number;
  perf_color;
  locked_out: boolean = false;
  selectedInGrid: boolean = false;
  eventNodeStatusForSort;
  confStatus: string;

  pre_event_buffer: number;
  post_event_buffer: number;
  chart_end_time: string;
  overall_performance_value: number;

  overlap?: EventNodeDateOverlap;
  constructor() {}
}

export class EventNodeDateOverlap {
  start: string;
  end: string;

  constructor(start: string = null, end: string = null) {
    this.start = start;
    this.end = end;
  }

  public hasOverlap() {
    return this.start !== null || this.end !== null;
  }

  public hasStartOverlap() {
    return this.start !== null;
  }

  public hasEndOverlap() {
    return this.end !== null;
  }

  public getStart() {
    return this.start;
  }

  public getEnd() {
    return this.end;
  }
}

class EventNodeNote {
  event_node_id: string;
  registration_id: string;
  site_id: string;
  event_id: string;
  note_id: string;
  created_by: string;
  note_dttm: string;
  case_type: string;
  note_text: string;
  expiration_dttm: string;
  deleted: boolean;
}

class LatestNote {
  note_id: string;
  created_by: string;
  note_dttm: string;
  last_updated_dttm: string;
  case_type: string;
  note_text: string;
  expiration_dttm: string;
  deleted: string;
}

class NoteDetails {
  event_node_id: string;
  count: number;
  latest_note: LatestNote;
}

export interface EventNodeBulkOperation {
  event_node_id: string;
  event_start_time?: string;
  event_end_time?: string;
  event_id?: string;
  operation?: string;
  portfolio?: string;
}

export enum RegistrationType {
  LOAD = 'LOAD',
  GENERATOR = 'GENERATOR',
  STORAGE = 'STORAGE',
  DEMAND_ON = 'DEMAND_ON',
  LOAD_METERED_GENERATOR = 'LOAD_METERED_GENERATOR',
}
export { EventNode, EventNodeWrapper, LatestNote, NoteDetails };
