import { Component, OnInit } from '@angular/core';
import { ListTab, SharedService } from "../../../services/shared.service";
import { MqttService } from "ngx-mqtt";
import { EventManagementService } from "../../../services/event-management.service";
import { ActivatedRoute } from "@angular/router";
import { EPSEvent } from "../../../classes/event";
import { Subject } from "rxjs/internal/Subject";
import { takeUntil } from "rxjs/operators";
import { EventNode } from "../../../classes/event_node";
import { Product } from "../../../classes/product";
import { Registration } from '../../../classes/registration';

@Component({
  selector: 'app-utility-portal-node-details',
  templateUrl: './utility-portal-node-details.component.pug',
  styleUrls: ['./utility-portal-node-details.component.scss']
})
export class UtilityPortalNodeDetailsComponent implements OnInit {

  event: EPSEvent;
  product:Product;
  eventNode : EventNode;
  errorLabel:string;
  eventLoaded:boolean = false;

  registration: Registration;

  eventNodeID = null;

  eventNodeSubscribe$;
  eventSubscribe$;
  eventDataSubscribe$;

  constructor(private ems:EventManagementService, private sharedService:SharedService, private route: ActivatedRoute, private mqttService: MqttService, private epsMqttService: MqttService) { }

  private ngUnsubscribe: Subject<any> = new Subject();

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {

    const controller = this;

    this.sharedService.setListTab((<any>ListTab['UTILITY_NODEDETAILS']));

    //Subscribe to any parameter change in the route (i.e the event ID) and if it changes, update the event object
    this.route.paramMap.subscribe(params => {
      controller.eventNodeID = params.get("id");
    });


    //GET EVENT NODE
    this.ems.get('/v1/event_node/' + this.eventNodeID, {}).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {
      this.eventNode = response.data;

      //GET EVENT
      this.ems.get('/v1/event/' + this.eventNode.event_id, {}).pipe(takeUntil(this.ngUnsubscribe)
      ).subscribe(response => {
        this.event = response.data;

        //GET PRODUCT
        this.ems.get('/v1/product/' + this.event.product_id, {}).pipe(takeUntil(this.ngUnsubscribe)
        ).subscribe(response => {
          this.product = response.data;
          this.eventLoaded = true;

        }, error => {
          console.dir("Error getting notes for event node with ID: " + this.eventNode.event_node_id);
          console.dir(error);
        });

      }, error => {
        console.dir("Error getting notes for event node with ID: " + this.eventNode.event_node_id);
        console.dir(error);
      });


      //GET REGISTRATION
       this.ems.get('/v1/registration/' + this.eventNode.registration_id, {}).pipe(takeUntil(this.ngUnsubscribe)
       ).subscribe(response => {
         this.registration = response;
         this.eventNode.registration_display_label = this.registration.display_label;
       }, error => {
         this.eventNode.registration_display_label = this.sharedService.flattenDisplayLabel(this.eventNode.site_display_label);
         console.dir("Error getting registration: " + this.eventNode.registration_id);
         console.dir(error);
       });

    }, error => {
      this.eventLoaded = true;
      this.errorLabel = "Error getting event node! See console for details.";
      console.dir("Error getting event node with ID: " + this.eventNodeID);
      console.dir(error);
    });
  }

  getPerfDisplay() {
    return this.eventNode.estimate_performance_ind ? ' - ' : this.sharedService.convertProductUOM(this.eventNode.registered_capacity_value, this.product, true);
  }

}
