import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { PaginationMeta } from '../../classes/paginationMeta';

import { EventManagementService } from '../../services/event-management.service';
import { RefDataService } from '../../services/ref-data.service';
import { SharedService } from '../../services/shared.service';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { Product } from '../../classes/product';

@Component({
  selector: 'app-node-list-filter',
  templateUrl: './node-list-filter.component.pug',
  styleUrls: ['./node-list-filter.component.scss']
})
export class NodeListFilterComponent implements OnInit, OnChanges{
  @Output() filteringComplete = new EventEmitter<any>();
  @Output() refDataRetrieved = new EventEmitter<any>();
  @Input() fetchingData;
  @Input() events;
  @Input() filterOnLoad = true;
  @Input() defaultSort;

  @Input() parentView: string;
  @Input() product: Product;

  minTextEntry = 3;

  controlTypeOptions;
  registrationTypeOptions;
  workflowStateOptions;
  portfolioOptions;

  eventNodeStatusOptions;
  confirmationStatusOptions;
  sortFieldOptions;
  sortOrderOptions;
  operatorOptions;


  gettingRefData = true;

  excludeContacted = false;
  excludeNoData = false;
  exclude_completed = false;


  productUOM;
  errorThreshold;
  warningThreshold;
  performingBy = 0;
  performingAboveBelowPerCent = 20;
  filterModel: NodeFilterModel;
  public modelToSend;

  constructor(private EMS: EventManagementService, private SS: SharedService, private RDS: RefDataService) { }

  ngOnInit(): void {
    this.defaultSort = this.parentView == 'heatmap' ? 'expected_capacity_value' : 'shortfall';
    this.filterModel = new NodeFilterModel(this.defaultSort);
    this.getRefData$().subscribe(
      ([workflowStatuses, regTypes, controlTypes])=>{
          this.workflowStateOptions = workflowStatuses;
          this.registrationTypeOptions = regTypes;
          this.controlTypeOptions = controlTypes;
          this.gettingRefData = false;
          setTimeout(()=>{this.onSubmit()})
      }
    )

    this.confirmationStatusOptions = [{code: null, display_label: 'All'},{code: true, display_label: 'Confirmed'}, {code: false, display_label: 'Unconfirmed'}];
    this.eventNodeStatusOptions=[{code: 'active', display_label: 'Active'}, {code: 'pending', display_label: 'Pending'}, {code: 'opted_out', display_label: 'Opted Out'}, {code: 'completed', display_label: 'Completed'}, {code: 'excluded', display_label: 'Excluded'}, {code: 'cancelled', display_label: 'Cancelled'}];
    this.sortFieldOptions = [{code: null, display_label: 'None'}, {code: 'registration_name', display_label: 'Registration Name'}, {code: 'site_name', display_label: 'Site Name'}, {code: 'expected_capacity_value', display_label: 'Expected Capacity'}, {code: 'start_time', display_label: 'Start Time'}, {code: 'event_node_status', display_label: 'Event Node Status'}, {code: 'shortfall', display_label: 'Shortfall'}, {code: 'registration_type', display_label: 'Registration Type'}, {code: 'organization_name', display_label: 'Organization Name'}, {code: 'control_type', display_label: 'Control Type'}, {code: 'assignee', display_label: 'Assignee'}, {code: 'confirmation_status', display_label: 'Confirmation Status'}, {code: 'workflow_state', display_label: 'Workflow State'}]
    this.sortOrderOptions = [{code: 'ASC', display_label: 'Ascending'}, {code: 'DESC', display_label: 'Descending'}]
    this.operatorOptions = [{code: 'ABOVE', display_label: 'above'}, {code: 'BELOW', display_label: 'below'}]

    if(this.parentView === 'agg'){
      _.remove(this.eventNodeStatusOptions, (o)=>{return o.code === 'opted_out'})
    }
    if(this.parentView == "heatmap"){
      _.remove(this.eventNodeStatusOptions, (o)=>{return o.code === 'cancelled' || o.code === 'excluded'})
    }

  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.product){
      this.productUOM = this.SS.getPrezUOM(this.product);
      this.errorThreshold = this.product?.performance_error_threshold || 50;
      this.warningThreshold = this.product?.performance_warning_threshold || 90;
    }
    if(changes.events?.currentValue){
      let currentIds = _.map(changes.events.currentValue, 'event_id')
      let previousIds = !!changes.events.previousValue ? _.map(changes.events.previousValue, 'event_id') : [];

      let diff = _.difference(currentIds, previousIds);
      if(diff.length){
        let arr = []

        this.events.forEach((event)=>{
          arr.push({portfolio_display_label: event.portfolio_display_label, id: event.portfolio_id})
        })
        this.portfolioOptions = _.uniqBy(arr, 'id');

        if(!this.gettingRefData){
          this.onSubmit()
        }
      }

    }
  }

  onReset(){
    this.filterModel = new NodeFilterModel(this.defaultSort);
    this.performingAboveBelowPerCent = 20;
    this.onSubmit()
  }

  getRefData$(): Observable<any>{
    const workFlowStatuses$ = this.RDS.getWorkflowStatuses().pipe(map((resp) => {return resp;}), catchError((err)=> {console.log(err); return from(new Promise((resolve) => resolve(true)));}));
    const regTypes$ = this.RDS.getRegistrationTypes().pipe(map((resp) => {return resp;}), catchError((err)=> {console.log(err); return from(new Promise((resolve) => resolve(true)));}));
    const controlTypes$ = this.RDS.getControlTypes().pipe(map((resp) => {return resp;}), catchError((err)=> {console.log(err); return from(new Promise((resolve) => resolve(true)));}));

    return forkJoin([workFlowStatuses$, regTypes$, controlTypes$]);
  }

  onSubmit(){

    let model = this.filterModel;

    let allStatuses = _.map(this.eventNodeStatusOptions, 'code');
    if(model.filter_performance){
      switch (this.performingBy){
        case 0:
          //percent
          model.performance_value = this.performingAboveBelowPerCent;
          break;
        case 1:
          //warning threshold
          model.performance_value = this.warningThreshold;
          break;
        case 2:
          //error threshold
          model.performance_value = this.errorThreshold;
          break;
      }
    }

    this.modelToSend = JSON.parse(JSON.stringify(this.filterModel));

    if(!this.modelToSend.event_node_status){
      this.modelToSend.event_node_status = allStatuses;
    }

    if(this.modelToSend.portfolios){
      let arr = []
      this.modelToSend.portfolios.forEach((portfolio)=>{
        let event = _.find(this.events, ['portfolio_id', portfolio]);
        arr.push(event.event_id);
      })
      this.modelToSend.eventIds = arr;
    } else {
      this.modelToSend.eventIds = _.map(this.events, 'event_id');
    }

    if(!this.modelToSend.event_node_status){
      this.modelToSend.event_node_status = allStatuses;
    }

    if(!this.modelToSend.sort){
      delete this.modelToSend.order
    }

    if(!this.modelToSend.filter_expected_capacity){
      delete this.modelToSend.expected_capacity_value;
      delete this.modelToSend.expected_capacity_operator;
    } else {
      this.modelToSend.expected_capacity_value = this.SS.convertToKW(this.modelToSend.expected_capacity_value, this.productUOM)
    }

    if(!this.modelToSend.filter_shortfall){
      delete this.modelToSend.shortfall_value;
      delete this.modelToSend.shortfall_operator;
    } else {
      this.modelToSend.shortfall_value = this.SS.convertToKW(this.modelToSend.shortfall_value, this.productUOM)
    }

    if(!this.modelToSend.filter_performance){
      delete this.modelToSend.performance_value;
      delete this.modelToSend.performance_operator;
    }

    if(!this.modelToSend.filter_target){
      delete this.modelToSend.target_value;
      delete this.modelToSend.target_operator;
    } else {
      this.modelToSend.target_value = this.SS.convertToKW(this.modelToSend.target_value, this.productUOM)
    }

    delete this.modelToSend.filter_expected_capacity;
    delete this.modelToSend.filter_shortfall;
    delete this.modelToSend.filter_performance;
    delete this.modelToSend.filter_target;

    this.filteringComplete.emit();
  }
}

class NodeFilterModel {
  constructor(defaultSort = 'shortfall'){
    this.organization_name = null;
    this.site_name = null;
    this.portfolios = null;
    this.event_node_status;
    this.registration_type = null;
    this.registration_name = null;
    this.control_type = null;
    this.assignee = null;
    this.confirmation_status = null;
    this.workflow_state = null;
    this.performance_value = 20;
    this.performance_operator = 'ABOVE';
    this.expected_capacity_value = 20;
    this.expected_capacity_operator = 'ABOVE';
    this.shortfall_value = 20;
    this.shortfall_operator = 'ABOVE';
    this.exclude_noted = false;
    this.exclude_unmetered = false;
    this.sort = defaultSort;
    this.order = 'DESC';
    this.filter_expected_capacity = false;
    this.filter_performance = false;
    this.filter_shortfall = false;
    this.eventIds = [];
  }
  organization_name;
  site_name;
  portfolios;
  event_node_status;
  registration_type = null;
  registration_name = null;
  control_type = null;
  assignee = null;
  confirmation_status = null;
  workflow_state = null;
  performance_value = 20;
  performance_operator = 'ABOVE';
  expected_capacity_value = 20;
  expected_capacity_operator = 'ABOVE';
  shortfall_value = 20;
  shortfall_operator = 'ABOVE';
  target_value = 20;
  target_operator = 'ABOVE';
  exclude_noted = false;
  exclude_unmetered = false;
  sort = 'shortfall';
  order = 'DESC';
  filter_expected_capacity = false;
  filter_performance = false;
  filter_shortfall = false;
  eventIds = [];
  filter_target = false;
}
export { NodeFilterModel };
